<template src="./addVendor.html"></template>

<script>
import { required, email, minLength, maxLength, alphaNum } from "vuelidate/lib/validators"
export default {
	name: "addVendor",
	props: ["vendorId", "vendorDetailResponse", "isEditable", "onSaveNewVendor", "showCancel", "cancelVendor", "inputVenderName"],
	data() {
		return {
			formattedPhoneNumber: null,
			isFormattedPhoneNumberValid: null,
			formattedSecondaryPhoneNumber: null,
			isFormattedSecondaryPhoneNumberValid: null,
		}
	},
	validations: {
		vendorDetailResponse: {
			response: {
				vendorName: {
					required,
				},
				primaryContactFirstName: {
					required,
				},
				primaryContactLastName: {
					required,
				},
				primaryContactPhoneNumber: {
					required,
					isValid(value) {
						// required field will take care
						if (value === "") return true

						return this.isFormattedPhoneNumberValid === true
					},
					isUnique(value) {
						// required field will take care
						if (value === "") return true

						if (this.vendorDetailResponse && this.vendorDetailResponse.err) {
							const { errno, errmsg } = this.vendorDetailResponse.err
							if (errno === 103) {
								return false
							}
						}
						return true
					},
				},
				primaryContactEmail: {
					required,
					email,
				},
				pan: {
					required,
					minLength: minLength(10),
					maxLength: maxLength(10),
					alphaNum,
				},
				pincode: {
					minLength: minLength(0),
					maxLength: maxLength(6),
				},
				// secContactPhoneNumber: {
				//     isValid(value) {
				//         if (value === '') return true;

				//         return this.isFormattedSecondaryPhoneNumberValid === true;
				//     }
				// }
			},
		},
	},
	methods: {
		getVendorDetail() {
			if (!this.vendorId) {
				return
			}

			this.$store
				.dispatch("fetchApiRequest", {
					apiMethod: "getVendorDetail",
					requestParams: {
						vendorId: this.vendorId,
						tenantId: this.$store.state.currentTenantId,
					},
				})
				.then((apiResponse) => {
					this.$set(this.vendorDetailResponse, "err", apiResponse.err)
					this.$set(this.vendorDetailResponse, "status", apiResponse.status)
					this.$set(this.vendorDetailResponse, "response", apiResponse.response)

					if (apiResponse && apiResponse.response && apiResponse.response.primaryContactPhoneNumber) {
						this.formattedPhoneNumber = apiResponse.response.primaryContactPhoneNumber || null
						if (this.formattedPhoneNumber) {
							this.isFormattedPhoneNumberValid = true
						}
						this.formattedSecondaryPhoneNumber = apiResponse.response.secContactPhoneNumber || null
						if (this.formattedSecondaryPhoneNumber) {
							this.isFormattedSecondaryPhoneNumberValid = true
						}
					}
					if (this.inputVenderName != null) {
						this.vendorDetailResponse.response.vendorName = this.inputVenderName
					}
				})
				.catch(console.error)
		},
		cancel() {
			this.$router.push({ path: `/settings/vendors` })
		},

		saveVendor() {
			this.$v.$touch()
			if (this.$v.$invalid) {
				this.$emit("validationMessage", this.$v)
				return
			}
			this.vendorDetail.tenantId = this.$store.state.currentTenantId

			this.$store
				.dispatch("fetchApiRequest", {
					apiMethod: "saveVendor",
					requestParams: this.vendorDetail,
				})
				.then((apiResponse) => {
					this.$set(this.vendorDetailResponse, "err", apiResponse.err)
					this.$set(this.vendorDetailResponse, "status", apiResponse.status)
					const { err, status, response } = apiResponse
					var errorvalidations = [103]
					if (err) {
						if (errorvalidations.indexOf(err.errno) == -1) {
							this.$toasted.error(err.errno ? err.errmsg : "An error occured while trying to save vendor")
						}
						return
					}
					if (apiResponse.response) {
						this.$set(this.vendorDetailResponse, "response", apiResponse.response)
						this.$toasted.success("Vendor details have been saved successfully.")
						if (this.vendorId !== apiResponse.response.vendorId) {
							this.onSaveNewVendor(apiResponse.response.vendorId)
						}
					}
				})
				.catch(console.error)
		},
		setPhoneNumber(number, value) {
			if (value && value.hasOwnProperty("isValid")) {
				this.isFormattedPhoneNumberValid = value.isValid
			}
			if (value && value.number && value.number.e164 && this.vendorDetailResponse && this.vendorDetailResponse.response) {
				this.$set(this.vendorDetailResponse.response, "primaryContactPhoneNumber", value.number.e164)

				// reset err if new mobile number was entered
				if (this.vendorDetailResponse && this.vendorDetailResponse.err && this.vendorDetailResponse.err.errno === 103) {
					this.vendorDetailResponse.err = null
				}
			}
		},
		setSecondaryContactPhoneNumber(number, value) {
			if (value && value.hasOwnProperty("isValid")) {
				this.isFormattedSecondaryPhoneNumberValid = value.isValid
			}
			if (value && value.number && value.number.e164 && this.vendorDetailResponse && this.vendorDetailResponse.response) {
				this.$set(this.vendorDetailResponse.response, "secContactPhoneNumber", value.number.e164)
			}
		},
	},
	computed: {
		currentWarehouseId() {
			return this.$store.state.currentWarehouseId
		},
		vendorDetail() {
			if (this.vendorDetailResponse && this.vendorDetailResponse.response) {
				return this.vendorDetailResponse.response
			}
			return null
		},
	},
	watch: {
		vendorId() {
			this.getVendorDetail()
		},
	},
	mounted() {
		this.getVendorDetail()
	},
	components: {
		uploadDocs: () => import("../../components/uploadDocs/uploadDocs"),
	},
}
</script>
