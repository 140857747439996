// This exports a default function that returns a deferred promise object
export default () => {
	// Create an empty object to hold the deferred promise
	let deferred = {}

	// Create a new Promise object and store it in the "promise" property of the deferred object
	deferred.promise = new Promise((resolve, reject) => {
		// Store the resolve and reject functions in the "resolve" and "reject" properties of the deferred object
		deferred.resolve = resolve
		deferred.reject = reject
	})

	// Return the deferred object, which includes the promise, resolve, and reject properties
	return deferred
}
