<template>
	<div id="app">
		<header class="navbar" v-if="user">
			<div class="logo"></div>
			<div class="navbarSelect">
				<!--				<template v-if="tenants.length > 1 && user.defaultTenantId">-->
				<!--					<label class="mb-0" for="header-tenant-select">-->
				<!--						<select-->
				<!--							class="userTenantSelect selectIconChange"-->
				<!--							name="header-tenant-select"-->
				<!--							id="header-tenant-select"-->
				<!--							v-model="currentTenantId"-->
				<!--							@change="changeUserTenant()"-->
				<!--							:title="currentTenantName"-->
				<!--						>-->
				<!--							<option v-for="tenant in tenants" :value="tenant.tenantId">-->
				<!--								{{ tenant.tenantName }}-->
				<!--							</option>-->
				<!--						</select>-->
				<!--					</label>-->
				<!--				</template>-->
				<!--				<template v-else>-->
				<!--					<span class="mb-0 p-1 singleLabel" :title="user.defaultTenantName">-->
				<!--						{{ user.defaultTenantName }}-->
				<!--					</span>-->
				<!--				</template>-->
				<span class="mb-0 p-1 singleLabel" :title="user.defaultTenantName">
					{{ user.defaultTenantName }}
				</span>
			</div>
			<div class="navbarSelect">
				<i class="material-icons">location_on</i>
				<template v-if="userWarehouses.length > 1 && user.defaultWarehouseId">
					<div class="mb-0 userWarehouseSelect multiselect-browserDefaultUI">
						<multiselect v-model="currentWarehouse" placeholder="Select" label="warehouseName" track-by="warehouseId" :options="userWarehouses" :show-no-results="false"> </multiselect>
					</div>
				</template>
				<template v-else>
					<span class="mb-0 p-1 singleLabel" :title="user.defaultWarehouseName">
						{{ user.defaultWarehouseName }}
					</span>
				</template>
			</div>
			<ul class="nav ml-auto">
				<li v-if="utility">
					<router-link to="/utility">
						<span class="menulabel">Utility</span>
					</router-link>
				</li>
				<li v-if="showDashboard">
					<router-link to="/dashboard">
						<i class="material-icons">bar_chart</i>
						<span class="menulabel">Dashboard</span>
					</router-link>
				</li>
				<li v-if="showReports">
					<router-link to="/reports">
						<i class="material-icons">reorder</i>
						<span class="menulabel">Reports</span>
					</router-link>
				</li>
				<li v-if="showWarehouseOrders">
					<router-link to="/pickLists">
						<i class="material-icons">list_alt</i>
						<span class="menulabel">Picklists</span>
					</router-link>
				</li>
				<!-- <li v-if="showOrders">
                    <router-link to="/orders">
                        <i class="material-icons">list_alt</i>
                        <span class="menulabel">{{(config["Orders"] && config["Orders"].labels["Order"]) ?
                            $tc(`message.${config["Orders"].labels["Order"]}`, 0) : '' }}</span>
                    </router-link>
                </li> -->
				<li v-if="showOrders && showNavTab">
					<router-link to="/projects">
						<i class="material-icons">list_alt</i>
						<span class="menulabel">{{ config["Orders"] && config["Orders"].labels["Order"] ? $tc(`message.${config["Orders"].labels["Order"]}`, 0) : "" }}</span>
					</router-link>
				</li>
				<li class="landing" v-if="showTripCreate && allowSchedulingFromScheduler">
					<router-link to="/scheduler">
						<i class="material-icons">date_range</i>
						<span class="menulabel">Trip Scheduler</span>
					</router-link>
				</li>
				<li v-if="showTripTracking && showNavTab">
					<router-link to="/triptracking">
						<i class="material-icons">local_shipping</i>
						<span class="menulabel">Trip Tracking</span>
					</router-link>
				</li>
				<!-- <li v-if="showTripTracking">
					<router-link to="/mobileWebPages/tripsList">
						<i class="material-icons">local_shipping</i>
						<span class="menulabel">Trip Tracking</span>
					</router-link>
				</li> -->
				<li v-if="showInbound">
					<router-link to="/inbound">
						<i class="material-icons">local_shipping</i>
						<span class="menulabel">Inbound</span>
					</router-link>
				</li>
				<li v-if="showKitting">
					<router-link to="/kitting">
						<i class="material-icons">list_alt</i>
						<span class="menulabel">Kitting</span>
					</router-link>
				</li>
				<li v-if="showSettings">
					<router-link to="/settings">
						<i class="material-icons">settings</i>
						<span class="menulabel">Settings</span>
					</router-link>
				</li>
			</ul>
			<div class="nav-profile">
				<a
					v-if="user"
					class="nav-link dropdown-toggle h-100"
					data-toggle="dropdown"
					href="javascript:void(0)"
					role="button"
					aria-haspopup="true"
					:aria-expanded="showUserProfilePanel"
					@click.stop="showUserProfilePanel = !showUserProfilePanel"
				>
					<label class="avatar-circle">
						<span class="initials">{{ (user.firstName + " " + user.lastName) | getAvator }}</span>
					</label>
				</a>
				<div class="dropdown-menu border m-0" style="display: block" v-if="showUserProfilePanel">
					<div class="userName">
						<i class="material-icons">account_circle</i>{{ user.firstName }}
						{{ user.lastName }}
					</div>
					<!-- <div class="navbarSelect px-3 py-2 m-0" v-if="false">
						<i class="material-icons">home_work</i>
						<template v-if="tenants.length > 1 && user.defaultTenantId">
							<label class="mb-0" for="header-tenant-select-profile">
								<select
									class="profilePop userTenantSelect selectIconChange"
									name="header-tenant-select-profile"
									id="header-tenant-select-profile"
									v-model="currentTenantId"
									@change="changeUserTenant()"
									:title="currentTenantName"
								>
									<option v-for="tenant in tenants" :value="tenant.tenantId" :key="tenant">
										{{ tenant.tenantName }}
									</option>
								</select>
							</label>
						</template>
						<template v-else>
							<span class="mb-0 p-1" :title="user.defaultTenantName">
								{{ user.defaultTenantName }}
							</span>
						</template>
					</div>
					<div class="navbarSelect px-3 py-2 m-0" v-if="false">
						<i class="material-icons">location_on</i>
						<template v-if="userWarehouses.length > 1 && user.defaultWarehouseId">
							<div class="mb-0 profilePop userWarehouseSelect multiselect-browserDefaultUI">
								<multiselect v-model="currentWarehouse" placeholder="Select" label="warehouseName" track-by="warehouseId" :options="userWarehouses" :show-no-results="false">
								</multiselect>
							</div>
						</template>
						<template v-else>
							<span class="mb-0 p-1" :title="user.defaultWarehouseName">
								{{ user.defaultWarehouseName }}
							</span>
						</template>
					</div> -->
					<a class="dropdown-item px-3 py-2 my-1" href="#" @click.stop="logout"> <i class="material-icons">logout</i> Logout</a>
				</div>
				<div @click.stop="showUserProfilePanel = !showUserProfilePanel" class="user-profile-panel-mask" v-if="showUserProfilePanel"></div>
			</div>
		</header>

		<router-view />
		<beat-loader :loading="!user" :color="'#00529B'"></beat-loader>
	</div>
</template>

<script>
import axios from "@/axios"
import idb from "@/app/Helpers/indexedDBHelpers/indexedDBApiRequest"

export default {
	name: "App",
	data() {
		return {
			showUserProfilePanel: false,
			currentTenantId: null,
			currentTenantName: null,
			utility: false,
			showNavTab: true,
		}
	},
	methods: {
		registeFCMToken() {
			if (localStorage.getItem("fcmToken")) {
				const fcmToken = localStorage.getItem("fcmToken")
				this.$store
					.dispatch("fetchApiRequest", {
						apiMethod: "addUserDeviceToken",
						requestParams: {
							tokenId: fcmToken,
							deviceType: "Web",
						},
					})
					.then((data) => {
						// console.log(data);
						console.log(data)
						console.log("FCM Token Registerd")
					})
					.catch((err) => {
						console.error(err)
					})
			}
		},
		async logout() {
			const removeUserDeviceToken = await this.$store.dispatch("fetchApiRequest", {
				apiMethod: "removeUserDeviceToken",
				requestParams: {
					tokenId: localStorage.getItem("fcmToken"),
					tenantId: localStorage.getItem("defaulttenant"),
				},
			})
			if (removeUserDeviceToken) {
				localStorage.removeItem("defaulttenant")
				localStorage.removeItem("authorizationToken")
			}
			const logout = await axios.get("logout")
			if (logout) {
				const searchParams = new URLSearchParams(window.location.search);
				const persmissionsToken = searchParams.get("permissions");
				const path = window.location.pathname
				if(!persmissionsToken){
					window.location.replace(logout.url)
				}else{
					window.location.replace(`${logout.url}?permissions=${persmissionsToken}&directNavUrl=${path}`)
				}
				if (window.indexedDB && typeof window.indexedDB.databases === "undefined") {
					const deleteId = async () => {
						try {
							idb.deleteIndexedDBDatabaseByName()
								.then(() => {
									localStorage.removeItem("indexedDBDatabases")
								})
								.catch(console.error)
						} catch ({ error }) {
							console.log("error while deleting idb")
						}
					}
					deleteId()
				} else {
					const deleteIdb = async () => {
						try {
							idb.deleteIndexedDBDataBase()
								.then(() => {
									localStorage.removeItem("indexedDBDatabases")
								})
								.catch(console.error)
						} catch (error) {
							console.log({ error })
							console.log("error while deleting idb")
						}
					}
					deleteIdb()
				}
			}
		},
		getDefaultRoute() {
			if (this.$store.getters.allowFncViewWarehouseOrders) return "Pick Lists"
			else if (this.$store.getters.allowFncViewOrders) return "Projects"
			else if (this.$store.getters.showTripCreate && this.$store.getters.allowSchedulingFromScheduler) return "Scheduler"
			else if (this.$store.getters.allowFncViewIbound) return "Inbound"
			else if (this.$store.getters.allowFncViewKitting) return "Kitting"
			else if (this.$store.getters.allowFncViewTrip) return "Trips List"
			else return "Page Not Found"
		},
		userDefaultNavigation() {
			if (!this.$router.history.ready) return
			if (!this.user) return
			if (this.$route.fullPath === "/" || (this.$route.meta.guard && !this.$store.getters[this.$route.meta.guard[0]])) {
				this.$router.push({ name: this.getDefaultRoute() })
			}
		},
		changeUserTenant() {
			this.showUserProfilePanel = false
			this.$store.dispatch("getUserProfileBasedOnTenant", {
				tenantId: this.currentTenantId,
				tenantName: this.tenants.find((tenant) => {
					return tenant.tenantId == this.currentTenantId
				}).tenantName,
			})
			this.$router.push("/")
		},
	},
	computed: {
		showKitting() {
			return this.$store.getters.allowFncViewKitting && this.$store.state.currentTenantType === "Inbound"
		},
		showInbound() {
			return this.$store.getters.allowFncViewIbound && this.$store.state.currentTenantType === "Inbound"
		},
		showTripTracking() {
			return this.$store.getters.allowFncViewTrip
		},
		showSettings() {
			return this.$store.getters.showSettings
		},
		warehouseId() {
			return this.$store.state.currentWarehouseId
		},
		userProfile() {
			const userState = this.$store.state.user
			if (userState && userState.status === "success") {
				return userState.response
			}
			return null
		},
		user() {
			return this.$store.state.user.response
		},
		userWarehouses() {
			return this.$store.state.user.response.warehouses
		},
		showTripCreate() {
			return this.$store.getters.showTripCreate
		},
		allowSchedulingFromScheduler() {
			return this.$store.getters.allowSchedulingFromScheduler
		},
		showReports() {
			return this.$store.getters.showReports
		},
		showDashboard() {
			return this.$store.getters.showDashboard
		},
		showOrders() {
			return this.$store.getters.allowFncViewOrders
		},
		showWarehouseOrders() {
			return this.$store.getters.allowFncViewWarehouseOrders
		},
		tenants() {
			return this.$store.state.user.response.tenants
		},
		tenantId() {
			return this.$store.state.currentTenantId
		},
		tenantName() {
			return this.$store.state.currentTenantName
		},
		currentWarehouse: {
			get() {
				if (Array.isArray(this.userWarehouses) && this.warehouseId) {
					return this.userWarehouses.filter((e) => e.warehouseId == this.warehouseId)[0]
				}
				return null
			},
			set(val) {
				if (val) {
					this.showUserProfilePanel = false
					this.$store.dispatch("setCurrentWarehouseId", val.warehouseId)
					this.$store.commit("setWarehouseTypeName", val.warehouseTypeName)
				}
			},
		},
		config() {
			return this.$store.state.tenantConfigurationJSON
		},
	},
	watch: {
		$route(to, from) {
			document.title = "LogiKal - " + (to && to.meta && to.meta.title ? to.meta.title : "")
		},
		userProfile(newValue, oldValue) {
			if (newValue) {
				this.userDefaultNavigation()
			}
		},
		tenantId() {
			this.currentTenantId = this.tenantId
		},
		tenantName() {
			this.currentTenantName = this.tenantName
		},
	},
	mounted() {
		if (this.warehouseId && this.userProfile) {
			// this.currentTenantId = this.tenantId;
		}
		this.registeFCMToken()
		this.$router.onReady(() => this.userDefaultNavigation())
		this.utility = localStorage.getItem("utility")
		const params = new URLSearchParams(window.location.search)
		const token = params.get("permissions")
		if (token) {
			this.showNavTab = false
		}
		// this.showSettingTab = JSON.parse(localStorage.getItem("showSettings")) ?? true
	},
	components: {
		BeatLoader: () => import("vue-spinner/src/BeatLoader.vue"),
	},
}
</script>
