import Vue from "vue"
import Vuex from "vuex"
import moment from "moment"
import Toasted from "vue-toasted"
import Vuelidate from "vuelidate"
import indexedDB from "./../Helpers/indexedDBHelpers/indexedDBApiRequest"
import { setUserId } from "@firebase/analytics"

import fetchApiRequest from "./fetchApiRequest"

// modules
import tripStore from "./modules/tripStore"
import tripTrackingStore from "./modules/tripTrackingStore"

Vue.use(Vuex)
Vue.use(Toasted, { position: "bottom-right", duration: 5000, keepOnHover: true })
Vue.use(Vuelidate)

export default new Vuex.Store({
	state: {
		timezone: "+05:30",
		user: null,
		currentWarehouseId: null,
		currentWarehouse: null,
		currentWarehouseTypeName: null,
		date: null,
		mapsServiceProvider: "googlemaps",
		tripStates: {
			1: "Created",
			2: "Vehicle Assigned",
			3: "Cancelled",
			4: "Trip Started",
			5: "Finished",
		},
		tripLegStates: {
			1: "Created",
			2: "Vehicle Assigned",
			3: "Cancelled",
			4: "Vehicle At Location",
			5: "Departed",
			6: "Arrived",
			7: "Finished",
		},
		tripLegStockStates: {
			101: "Loading Started",
			102: "Loading Completed",
			103: "Unloading Started",
			104: "Unloading Completed",
		},
		tripLegGatepassStates: {
			201: "Gatepass Generated",
			202: "Gatepass Approved at Source",
			203: "Gatepass Verified",
			204: "Gatepass Approved At Destination",
		},
		tripLegStateTextObj: {
			"1::null::null": { text: "Created", className: "created" },
			"2::null::null": { text: "Vehicle Assigned", className: "vehicleassigned" },
			"3::null::null": { text: "Cancelled", className: "cancelled" },
			"3::101::null": { text: "Cancelled", className: "cancelled" },
			"3::101::201": { text: "Cancelled", className: "cancelled" },
			"3::102::201": { text: "Cancelled", className: "cancelled" },
			"3::102::202": { text: "Cancelled", className: "cancelled" },
			"4::null::null": { text: "Vehicle At Location", className: "vehicleatlocation" },
			"4::101::null": { text: "Loading Started", className: "loadingstarted" },
			"4::101::201": { text: "Gatepass Generated", className: "gatepassgenerated" },
			"4::102::null": { text: "Loading Completed", className: "loadingcompleted" },
			"4::102::201": { text: "Loading Completed", className: "loadingcompleted" },
			"4::102::202": {
				text: "Gatepass Approved at Source",
				className: "gatepassapprovedatsource",
			},
			"5::102::202": { text: "Departed", className: "departed" },
			"5::null::202": { text: "Departed", className: "departed" },
			"5::102::null": { text: "Departed", className: "departed" },
			"5::null::null": { text: "Departed", className: "departed" },
			"6::102::202": { text: "Arrived", className: "arrived" },
			"6::null::202": { text: "Arrived", className: "arrived" },
			"6::102::null": { text: "Arrived", className: "arrived" },
			"6::null::null": { text: "Arrived", className: "arrived" },
			"6::103::null": { text: "Arrived", className: "arrived" },
			"6::103::202": { text: "Unloading Started", className: "unloadingstarted" },
			"6::103::null": { text: "Unloading Started", className: "unloadingstarted" },
			"6::103::203": { text: "Gatepass Verified", className: "gatepassverified" },
			"6::104::203": { text: "Unloading Completed", className: "unloadingcompleted" },
			"6::104::204": {
				text: "Gatepass Approved at Destination",
				className: "gatepassapprovedatdestination",
			},
			"7::104::204": { text: "Finished", className: "finished" },
			"7::102::204": { text: "Finished", className: "finished" },
			"7::null::204": { text: "Finished", className: "finished" },
			// For HKMCF
			"7::104::null": { text: "Finished", className: "finished" },
			"6::104::null": { text: "Arrived", className: "arrived" },
			"3::102::null": { text: "Cancelled", className: "cancelled" },
			//Ammamma

			"1::null::202": { text: "Created", className: "created" },
			"2::null::202": { text: "Vehicle Assigned", className: "vehicleassigned" },
			"4::null::202": { text: "Vehicle At Location", className: "vehicleatlocation" },
			"6::104::202": { text: "Unloading Completed", className: "unloadingcompleted" },
			"3::null::202": { text: "Cancelled", className: "cancelled" },
			// For atlas copco
			"1::102::202": { text: "Created", className: "created" },
		},
		settings: [
			{
				name: "Roles",
				value: "roles",
				splFn: "allowFncViewRole",
				icon: "icon-roles",
				desc: "Create Roles as per business needs. Manage and bundle permissions to define access control to every module of the system.",
			},
			{
				name: "Users",
				value: "users",
				splFn: "allowFncViewUser",
				icon: "icon-users",
				desc: "Add Users. Update User information and their associated Roles and Locations.",
			},
			{
				name: "Locations",
				value: "locations",
				splFn: "allowFncViewWarehouse",
				icon: "icon-warehouses",
				desc: "All Locations Master Data with all required location metrics and slot details.",
			},
			{
				name: "Routes",
				value: "routes",
				splFn: "allowFncViewRoute",
				icon: "icon-routePlanner",
				desc: "Add/Update all Routes Master Data.",
			},
			// { name: "Customers", value: "warehouses", splFn: "allowFncViewWarehouse", icon: "icon-warehouses", desc: "Add/Update all Customer information." },
			{
				name: "Vendors",
				value: "vendors",
				splFn: "allowFncVendor",
				icon: "icon-vendors",
				desc: "Add/Update all Vehicle-Vendor information.",
			},
			{
				name: "Vehicles",
				value: "vehicles",
				splFn: "allowFncViewVehicle",
				icon: "icon-vehicles",
				desc: "Add/Update all Vehicle Master Data.",
			},
			{
				name: "Contracts",
				value: "contracts",
				splFn: "allowFncViewContarct",
				icon: "icon-contracts",
				desc: "Add/Update all Contract’s information.",
			},
			{
				name: "Drivers",
				value: "drivers",
				splFn: "allowFncViewDriver",
				icon: "icon-drivers",
				desc: "Add/Update all Driver related information.",
			},
			{
				name: "GPS Devices",
				value: "gpsunits",
				splFn: "allowFncViewGpsUnit",
				icon: "icon-gpsTracker",
				desc: "Manage all GPS devices",
			},
			{
				name: "Crates Master",
				value: "warehousecratesorders",
				splFn: "allowFncViewCrateOrder",
				icon: "icon-crateOrders",
				desc: "Manage all Crate Master Data belonging to each Mother DC.",
			},
			{
				name: "Tenant Config",
				value: "tenantconfig",
				splFn: "allowFncViewTenantConfig",
				icon: "icon-tenant-config",
				desc: "Manage all tenant related configuration settings.",
			},
		],
		selectedDCMetricsReport: null,
		selectedDCMetricsTripType: null,
		currentTenantId: null,
		currentTenantName: null,
		currentTenantType: null,
		tenantConfigurationJSON: {},
		metadataLoading: false,
		orderDeliveryTypes: null,
		orderStatus: null,
		orderItemStatus: null,
		warehousesList: null,
		warehouseOrderStatus: null,
		// these are for dashboard clickables
		reportsFilters: {
			date: null,
			tripsFTD: null,

		}
	},
	mutations: {
		// @TODO Change Frontend Date Format
		setDate(state, incr) {
			state.date = moment()
				.add(incr || 0, "days")
				.format("YYYY-MM-DD")
		},
		setUserProfile(state, payload) {
			state.user = payload
			state.currentWarehouseId = payload.response && payload.response.defaultWarehouseId ? payload.response.defaultWarehouseId : null
			state.currentTenantId = payload.response && payload.response.defaultTenantId ? payload.response.defaultTenantId : null
			state.currentTenantType = payload.response && payload.response.defaultTenantType ? payload.response.defaultTenantType : null
			state.currentTenantName = payload.response && payload.response.defaultTenantName ? payload.response.defaultTenantName : null
			state.tenantConfigurationJSON = payload.response && payload.response.tenantConfigurationJSON ? payload.response.tenantConfigurationJSON : {}
			//payload.response && payload.response.tenants ? payload.response.tenants[0].tenantId : null;
			let defaultWarehouseId = localStorage.getItem("defaultWarehouseId")
			if (defaultWarehouseId) {
				defaultWarehouseId = parseInt(defaultWarehouseId)
				if (payload.response?.warehouses.map(({ warehouseId }) => warehouseId).includes(defaultWarehouseId)) {
					state.currentWarehouseId = defaultWarehouseId
				}
			}
			state.currentWarehouseTypeName =
				payload.response && payload.response?.warehouses?.length > 0 ? payload.response?.warehouses.find((wh) => wh.warehouseId === state.currentWarehouseId)?.warehouseTypeName : null
		},
		setCurrentWarehouse(state, payload) {
			state.currentWarehouse = payload
		},
		setCurrentWarehouseId(state, payload) {
			state.currentWarehouseId = payload
			localStorage.setItem("defaultWarehouseId", state.currentWarehouseId.toString())
		},
		setSelectedDCMetricsReport(state, payload) {
			state.selectedDCMetricsReport = payload
		},
		setSelectedDCMetricsTripType(state, payload) {
			state.selectedDCMetricsTripType = payload
		},
		setcurrentTenantId(state, tenantId) {
			state.currentTenantId = tenantId
		},
		setcurrentTenantName(state, tenantName) {
			state.currentTenantName = tenantName
		},
		setMetadataLoadingStatus(state, value) {
			state.metadataLoading = value
		},
		setTenantMetadata(state, payload) {
			state.orderDeliveryTypes = payload.orderDeliveryTypes || []
			state.orderStatus = payload.orderStatus || []
			state.orderItemStatus = payload.orderItemStatus || []
			state.warehousesList = payload.warehousesList || []
			state.warehouseOrderStatus = payload.warehouseOrderStatus || []
			state.crateTypes = payload.crateTypes || []
		},
		setKittingOrderlist(state, payload) {
			state.kittingOrderList = payload || []
		},
		setWarehouse(state, payload) {
			if (Array.isArray(state.warehousesList)) {
				state.warehousesList.push(payload)
			} else {
				state.warehousesList = [payload]
			}
		},
		setWarehouseTypeName(state, payload) {
			state.currentWarehouseTypeName = payload
		},
	},
	actions: {
		async fetchApiRequest({ state }, payload) {
			if (!(payload.apiMethod && payload.requestParams)) {
				return { err: "Invalid Parameters", status: "error" }
			}

			const response = await fetchApiRequest(payload.apiMethod, payload.requestParams)
			// console.log(response)
			return response
		},
		async sendData({ state }, payload) {
			const formData = new FormData()

			for (const param in payload.requestParams) {
				formData.append(param, payload.requestParams[param])
			}
			const request = await fetchApiRequest(payload.apiMethod, formData, "multipart/form-data")
			return request
		},
		async sendBlobData({ state }, payload) {
			const formData = new FormData()

			for (const param in payload.requestParams) {
				if (Array.isArray(payload.requestParams[param])) {
					payload.requestParams[param].forEach((v) => formData.append(param, v))
				} else {
					formData.append(param, payload.requestParams[param])
				}
			}
			const request = await fetchApiRequest(payload.apiMethod, formData, "multipart/form-data")
			return request
		},
		async getUserProfile({ state, commit }, { tenantId }) {
			if (state.user) {
				// don't make a server call if already made
				return
			}
			commit("setUserProfile", { err: null, response: null, status: "loading" })
			const apiResponse = await fetchApiRequest("getUserProfile", { tenantId })
			if (apiResponse && apiResponse.status == "success" && Array.isArray(apiResponse.response.functions)) {
				setUserId(Vue.prototype.$analytics, apiResponse.response.userId.toString()) // Set User ID for Firebase Analytics
				// Remove this if not required }
				apiResponse.response.functions = apiResponse.response.functions.reduce((acc, permissionObj) => {
					acc[permissionObj.functionId] = true
					return acc
				}, {})
			}
			commit("setUserProfile", apiResponse)
			commit("setcurrentTenantName", apiResponse.response.defaultTenantName)
		},
		async setCurrentWarehouseId({ state, commit }, payload) {
			await indexedDB.deleteIndexedDBDataBase()
			commit("setCurrentWarehouseId", payload)
		},
		async setWarehouseTypeName({ state, commit }, payload) {
			commit("setWarehouseTypeName", payload)
		},
		async getUserProfileBasedOnTenant({ state, commit }, { tenantId, tenantName }) {
			// if (state.user) { // don't make a server call if already made
			//     return;
			// }
			//  let tenantdata = JSON.parse(localStorage.getItem("tenants"))
			// tenantdata = tenantdata.find((item) => { return item.tenantId == tenantId })
			commit("setUserProfile", { err: null, response: null, status: "loading" })
			const apiResponse = await fetchApiRequest("getUserProfile", {
				tenantId: tenantId,
				// userId: tenantdata.userId
				//  tenantName: tenantdata.tenantName
			})
			if (apiResponse && apiResponse.status == "success" && Array.isArray(apiResponse.response.functions)) {
				// Removed this if not required if (localStorage.getItem("tenants")) {
				// Removed this if not required apiResponse.response.tenants = apiResponse.response.tenants;
				// Removed this if not required }
				apiResponse.response.functions = apiResponse.response.functions.reduce((acc, permissionObj) => {
					acc[permissionObj.functionId] = true
					return acc
				}, {})
				localStorage.setItem("defaulttenant", tenantId)
			}
			commit("setUserProfile", apiResponse)
			commit("setcurrentTenantId", tenantId)
			commit("setcurrentTenantName", tenantName)
		},
		async getTenantMetadata({ state, commit, dispatch, rootState }, { tenantId }) {
			if (!(tenantId && rootState.user && rootState.user.response && rootState.user.response.userId)) {
				return
			}
			commit("setMetadataLoadingStatus", true)
			let requestParams = { tenantId }
			let data = await indexedDB.indexedDBFetchApi("getTenantMetadata", requestParams, rootState.user.response.userId)
			if (data) {
				commit("setTenantMetadata", data.response || {})
				commit("setMetadataLoadingStatus", false)
			} else {
				console.log(data, "Could not get tenant metadata!!")
			}
		},
		async getKittingOrders({ state, commit, dispatch, rootState }, { tenantId }) {
			if (!(tenantId && rootState.user && rootState.user.response && rootState.user.response.userId)) {
				return
			}
			commit("setMetadataLoadingStatus", true)
			let requestParams = { tenantId }
			let data = await indexedDB.indexedDBFetchApi("getKittingOrders", requestParams, rootState.user.response.userId)
			if (data) {
				commit("setKittingOrderlist", data.response || {})
			} else {
				console.log(data, "Could not get tenant metadata!!")
			}
		},
	},
	getters: {
		getTenantType: (state) => {
			return state.user.response.defaultTenantType
		},
		// allowCreateRole: (state, getters) => {
		//     return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateRole"];
		// },
		// allowCreateUser: (state, getters) => {
		//     return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateUser"];
		// },
		// allowCreateVehicleContact: (state, getters) => {
		//     return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateVehicleContract"];
		// },
		// allowCreateWarehouse: (state, getters) => {
		//     return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateWarehouse"];
		// },

		allowViewFurniturePlantActivity: (state) => {
			return state.user && state.user.response && state.user.response.email && state.user.response.functions && state.user.response.functions["fncFurniturePlantActivityReport"]
		},
		allowScheduling: (state, getters) => {
			var warehouse = null
			var allowScheduling = null
			if (state.user && state.user.response && state.user.response.warehouses) {
				warehouse = state.user.response.warehouses.filter((warehouse) => {
					return warehouse.warehouseId == state.currentWarehouseId
				})
				if (warehouse && warehouse.length > 0) allowScheduling = warehouse[0].allowScheduling
			}
			return allowScheduling
		},
		allowSchedulingFromScheduler: (state, getters) => {
			return getters.allowScheduling && state.tenantConfigurationJSON.Scheduler
		},
		allowSchedulingFromTripTracking: (state, getters) => {
			return getters.allowScheduling && !getters.allowSchedulingFromScheduler
		},
		allowCopySchedule: (state) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCopySchedule"]
		},
		allowClearSchedule: (state) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncClearSchedule"]
		},
		allowCreateWarehouseToWarehouseMetrics: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateWarehouseToWarehouseMetrics"]
		},
		allowAssignUserToRole: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncAssignUserToRole"]
		},
		allowAllAttributes: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncAllAttributes"]
		},
		allowDCDashboards: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncDCDashboards"]
		},
		allowVehicleDashboards: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncVehicleDashboards"]
		},
		allowDCWise5KWiseOnTime: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncDCWise5KWiseOnTime"]
		},
		allowDetailedAssetsTracking: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncDetailedAssetsTracking"]
		},
		allowAssetsSummaryMTD: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncAssetsSummaryMTD"]
		},
		allowDriverLogin: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncDriverLogin"]
		},
		allowDriverAction: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncDriverAction"]
		},
		allowVehicleWiseSummary: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncVehicleWiseSummary"]
		},
		allowGatepassReport: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncGatepassReport"]
		},
		allowTransportBilling: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncMonthlyBilling"]
		},
		allowPickAndCheckEfficiencyReport: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncPickCheckEfficiencyReport"]
		},
		allowRoutewiseAttributesReport: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncRoutewiseAttributesReport"]
		},
		showSettings: (state, getters) => {
			return state.settings.some((setting) => getters[setting.splFn])
		},
		showDashboard: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncDashboard"]
		},
		showReports: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncReports"]
		},
		//trip menu and actions
		showTripCreate: (state, getters) => {
			return state.user && state.user.response && state.user.response.userTypeId === 1 && state.user.response.functions["fncCreateTrip"]
		},
		showTripUpdate: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateTrip"]
		},
		showTripCancel: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCancelTrip"]
		},
		// additional trip attributes
		showPickAndDelivery: (state) => {
			return state.tenantConfigurationJSON.Trip && (state.tenantConfigurationJSON.Trip.formFields || []).includes("Picking & Delivery")
		},
		showStockType: (state) => {
			return state.tenantConfigurationJSON.Trip && (state.tenantConfigurationJSON.Trip.formFields || []).includes("Stock Type")
		},
		showDestinationWarehouseUsersManagement: (state) => {
			return (
				state.tenantConfigurationJSON.Trip &&
				state.tenantConfigurationJSON.Trip.destinationWarehouseUsersManagement &&
				state.tenantConfigurationJSON.Trip.destinationWarehouseUsersManagement.enable
			)
		},
		// setting menu view privileges

		allowFncViewDriver: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewDriver"]
		},
		allowFncVendor: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewVendor"]
		},
		allowFncViewVehicle: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewVehicle"]
		},
		allowFncViewContarct: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewVehicleContract"]
		},
		allowFncViewRole: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewRole"]
		},
		allowFncViewTrip: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewTrip"]
		},
		allowFncViewWarehouse: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewWarehouse"]
		},
		allowFncViewUser: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewUser"]
		},
		allowFncViewCrateOrder: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewCrateOrder"]
		},
		allowFncViewOrders: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewOrder"]
		},
		allowFncViewWarehouseOrders: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewWarehouseOrder"]
		},
		allowFncViewRoute: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewRoute"]
		},
		allowFncViewGpsUnit: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewGpsunit"]
		},
		allowFncViewTenant: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewTenant"]
		},
		// setting menu create privileges
		allowFncCreateDriver: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateDriver"]
		},
		allowFncCreateVendor: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateVendor"]
		},
		allowFncCreateVehicle: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateVehicle"]
		},
		allowFncCreateContract: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateVehicleContract"]
		},
		allowFncCreateRole: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateRole"]
		},
		allowFncCreateTrip: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateTrip"]
		},
		allowFncCreateWarehouse: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateWarehouse"]
		},
		allowFncCreateUser: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateUser"]
		},
		allowFncCreateCrateOrder: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateCrateOrder"]
		},
		allowFncCreateOrders: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateOrder"]
		},
		allowFncCreateWarehouseOrders: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateWarehouseOrder"]
		},
		allowFncCreateRoute: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateRoute"]
		},
		allowFncCreateGpsUnit: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateGpsunit"]
		},
		allowFncCreateTenant: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateTenant"]
		},
		// setting menu Update privileges
		allowFncUpdateDriver: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateDriver"]
		},
		allowFncUpdateVendor: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateVendor"]
		},
		allowFncUpdateVehicle: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateVehicle"]
		},
		allowFncUpdateContract: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateVehicleContract"]
		},
		allowFncUpdateRole: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateRole"]
		},
		allowFncUpdateWarehouse: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateWarehouse"]
		},
		allowFncUpdateUser: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateUser"]
		},
		allowFncUpdateCrateOrder: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateCrateOrder"]
		},
		allowFncUpdateOrders: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateOrder"]
		},
		allowFncUpdateWarehouseOrders: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateWarehouseOrder"]
		},
		allowFncUpdateRoute: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateRoute"]
		},
		allowFncUpdateGpsUnit: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateGpsunit"]
		},
		allowFncUpdateTenant: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateTenant"]
		},
		// setting menu Delete privileges
		allowFncDeleteRoute: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncDeleteRoute"]
		},
		allowFncDeleteWarehouseOrders: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncDeleteWarehouseOrder"]
		},
		// Security permissions for odometer
		allowFncViewOdometer: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewOdometer"]
		},
		allowFncUpdateOdometer: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateOdometer"]
		},
		allowFncCreateOdometer: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncCreateOdometer"]
		},
		allowFncViewTenantConfig: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewTenantConfig"]
		},
		allowFncUpdateTenantConfig: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncUpdateTenantConfig"]
		},
		// Ibound Tenant functions
		allowFncViewIbound: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncViewInboundConsignments"]
		},
		allowFncViewKitting: (state, getters) => {
			return state.user && state.user.response && state.user.response.functions && state.user.response.functions["fncKittingViewOrder"]
		}
	},
	modules: {
		tripStore,
		tripTrackingStore,
	},
})
