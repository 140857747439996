import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

// should not be from components, should be from views

const routes = [
	{
		path: "/",
	},
	{
		path: "/reports/:report?/:attr?",
		name: "Reports",
		component: () => import("../views/reports/reports.vue"),
		props: true,
		meta: {
			title: "Reports",
			guard: ["showReports"]
		},
		children: [
			{
				path: "allAttributesReport",
				name: "allAttributes",
				component: () => import("../views/reports/allAttributes/allAttributes.vue"),
				meta: {
					title: "All Attributes",
					guard: ["allowAllAttributes"]
				},
			},
			{
				path: "dcLevelDashboardReport",
				name: "dcLevelDashboard",
				component: () => import("../views/reports/dcLevelDashboard/dcLevelDashboard.vue"),
				meta: {
					title: "DC Dashboards",
					guard: ["allowDCDashboards"]
				},
			},
			{
				path: "vehicleLevelDashboardReport",
				name: "vehicleLevelDashboard",
				component: () => import("../views/reports/vehicleLevelDashboard/vehicleLevelDashboard.vue"),
				meta: {
					title: "Vehicle Dashboards",
					guard: ["allowVehicleDashboards"]
				},
			},
			{
				path: "dcWiseOnTimeDashboardReport",
				component: () => import("../views/reports/dcWiseOnTimeDashboard/dcWiseOnTimeDashboard.vue"),
				meta: {
					title: "DC Wise-5K Wise On Time",
					guard: ["allowDCWise5KWiseOnTime"]
				},
			},
			{
				path: "detailedAssetsTrackingReport",
				component: () => import("../views/reports/detailedAssetsTracking/detailedAssetsTracking.vue"),
				meta: {
					title: "Detailed Assets Tracking",
					guard: ["allowDetailedAssetsTracking"]
				},
			},
			{
				path: "assetSummaryMTDReport",
				component: () => import("../views/reports/assetSummaryMTD/assetSummaryMTD.vue"),
				meta: {
					title: "Assets Summary MTD",
					guard: ["allowAssetsSummaryMTD"]
				},
			},
			{
				path: "driverLoginReport",
				component: () => import("../views/reports/driverLogin/driverLogin.vue"),
				meta: {
					title: "Driver Login",
					guard: ["allowDriverLogin"]
				},
			},
			{
				path: "driverActionReport",
				component: () => import("../views/reports/driverAction/driverAction.vue"),
				meta: {
					title: "Driver Action",
					guard: ["allowDriverAction"]
				},
			},
			{
				path: "vehicleWiseSummaryReport",
				component: () => import("../views/reports/vehicleWiseSummary/vehicleWiseSummary.vue"),
				meta: {
					title: "Vehicle Wise Summary",
					guard: ["allowVehicleWiseSummary"]
				},
			},
			{
				path: "gatepassReport",
				component: () => import("../views/reports/gatepassReport/gatepassReport.vue"),
				meta: {
					title: "Gatepass Report",
					guard: ["allowGatepassReport"]
				},
			},
			{
				path: "transportBillingReport",
				component: () => import("../views/reports/transportBilling/transportBilling.vue"),
				meta: {
					title: "Transport Billing Report",
					guard: ["allowTransportBilling"]
				},
			},
			{
				path: "pickAndCheckEfficiencyReport",
				component: () => import("../views/reports/pickAndCheckEfficiencyReport/pickAndCheckEfficiencyReport.vue"),
				meta: {
					title: "Pick & Check Efficiency",
					guard: ["allowPickAndCheckEfficiencyReport"]
				},
			},
			{
				path: "routewiseAttributesReport",
				component: () => import("../views/reports/routewiseAttributesReport/routewiseAttributesReport.vue"),
				meta: {
					title: "Route Wise Trip Details",
					guard: ["allowRoutewiseAttributesReport"]
				},
			},
			// {
			// 	path: "furniturePlantActivityReports",
			// 	name: "furniturePlantActivityReports",
			// 	component: () => import("@/app/views/reports/warehouseOrders/furniturePlantActivityReports.vue"),
			// 	meta: {
			// 		title: "warehouse Orders",
			// 	},
			// },
		],
	},
	{
		path: "/dashboard/:date?",
		name: "Dashboard",
		component: () => import("../views/dashboard/dashboard.vue"),
		meta: {
			title: "Dashboard",
			guard: ["showDashboard"]
		},
	},
	{
		path: "/scheduler",
		name: "Scheduler",
		component: () => import("../views/scheduler/scheduler.vue"),
		meta: {
			title: "Scheduler",
			guard: ["allowSchedulingFromScheduler", "showTripCreate"]
		},
	},
	{
		path: "/triptracking/:tripId?/:tripLegId?",
		name: "Trips List",
		component: () => import("../views/tripTracking/tripsList/tripsList.vue"),
		meta: {
			title: "Arrivals & Departures",
			guard: ["allowSchedulingFromScheduler"]
		},
	},
	{
		path: "/mobileWebPages/tripDetails/:tripId/:tripLegId",
		name: "Trips Details",
		component: () => import("../views/tripTracking/tripsList/mobileWebPages/mobileTripDetails/mobileTripDetails.vue"),
		meta: {
			title: "Arrivals & Departures",
			// guard: ["allowSchedulingFromScheduler"]
		},
	},
	{
		path: "/mobileWebPages/verifyGatePass/:tripId/:tripLegId",
		name: "Gatepass Orders",
		component: () => import("../views/tripTracking/tripsList/mobileWebPages/mobileGatePassVerification/mobileGatePassVerification.vue"),
		meta: {
			title: "Arrivals & Departures",
			// guard: ["allowSchedulingFromScheduler"]
		},
	},
	// {
	// 	path: "/mobileWebPages/tripsList",
	// 	name: "Trips List",
	// 	component: () => import("../views/tripTracking/tripsList/mobileWebPages/tripsList/tripsList.vue"),
	// 	meta: {
	// 		title: "Arrivals & Departures",
	// 		guard: ["allowSchedulingFromScheduler"]
	// 	},
	// },
	{
		path: "/trip/:id",
		name: "Trip Detail",
		component: () => import("../views/tripTracking/tripDetail/tripDetail.vue"),
		meta: {
			title: "Trip Detail",
		},
	},
	{
		path: "/tasks",
		name: "Tasks",
		component: () => import("../views/tripTaskListWrapper/tripTaskListWrapper.vue"),
		meta: {
			title: "Tasks",
		},
	},
	{
		path: "/settings",
		name: "Settings",
		component: () => import("../views/settings/settings.vue"),
		meta: {
			title: "Settings",
			guard: ["showSettings"]
		},
		children: [
			{
				path: "users",
				component: () => import("../views/settings/users/usersList/usersList.vue"),
				meta: {
					title: "Users",
				},
			},
			{
				path: "users/:id",
				component: () => import("../views/settings/users/userDetail/userDetail.vue"),
				meta: {
					title: "User Detail",
				},
			},
			{
				path: "roles",
				component: () => import("../views/settings/roles/rolesList/rolesList.vue"),
				meta: {
					title: "Roles",
				},
			},
			{
				path: "roles/:id",
				component: () => import("../views/settings/roles/roleDetail/roleDetail.vue"),
				meta: {
					title: "Role Detail",
				},
			},
			{
				path: "locations",
				component: () => import("../views/settings/warehouses/warehouseList/warehouseList.vue"),
				meta: {
					title: "Locations",
				},
			},
			{
				path: "locations/:id",
				component: () => import("../views/settings/warehouses/warehouseDetail/warehouseDetail.vue"),
				meta: {
					title: "Location Detail",
				},
			},
			{
				path: "contracts",
				component: () => import("../views/settings/contracts/contractsList/contractsList.vue"),
				meta: {
					title: "Contracts",
				},
			},
			{
				path: "contracts/:id",
				component: () => import("../views/settings/contracts/contractDetail/contractDetail.vue"),
				meta: {
					title: "Contract Detail",
				},
			},
			{
				path: "vendors",
				component: () => import("../views/settings/vendors/vendorList/vendorList.vue"),
				meta: {
					title: "Vendors",
				},
			},
			{
				path: "vendors/:id",
				component: () => import("../views/settings/vendors/vendorDetail/vendorDetail.vue"),
				meta: {
					title: "Vendor Detail",
				},
			},
			{
				path: "vehicles",
				component: () => import("../views/settings/vehicles/vehicleList/vehicleList.vue"),
				meta: {
					title: "Vehicles",
				},
			},
			{
				path: "vehicles/:id",
				component: () => import("../views/settings/vehicles/vehicleDetail/vehicleDetail.vue"),
				meta: {
					title: "Vehicle Details",
				},
			},
			{
				path: "drivers",
				component: () => import("../views/settings/drivers/driverList/driverList.vue"),
				meta: {
					title: "Drivers",
				},
			},
			{
				path: "drivers/:id",
				component: () => import("../views/settings/drivers/driverDetail/driverDetail.vue"),
				meta: {
					title: "Driver Details",
				},
			},
			{
				path: "warehousecratesorders",
				component: () => import("../views/settings/warehouseCrateOrders/warehouseCratesOrdersList/warehouseCratesOrdersList.vue"),
				meta: {
					title: "Crates Orders",
				},
			},
			{
				path: "warehousecratesorders/:id",
				component: () => import("../views/settings/warehouseCrateOrders/warehouseCratesOrder/warehouseCratesOrder.vue"),
				meta: {
					title: "Crate Order Detail",
				},
			},
			{
				path: "routes",
				component: () => import("../views/settings/routes/routesList/routesList.vue"),
				meta: {
					title: "Routes",
				},
			},
			{
				path: "gpsunits",
				component: () => import("../views/settings/gpsUnits/gpsUnitsList/gpsUnitsList.vue"),
				meta: {
					title: "GPS Devices",
				},
			},
			{
				path: "tenantconfig",
				component: () => import("../views/settings/tenantConfig/tenantConfig.vue"),
				meta: {
					title: "Tenant Config",
				},
			},
		],
	},
	{
		path: "/projects",
		name: "Projects",
		component: () => import("../views/projects/projects.vue"),
		meta: {
			title: "Projects",
			guard: ["allowFncViewOrders"]
		},
		children: [
			{
				path: "projectDetail/:id",
				component: () => import("../views/projects/projectDetail/projectDetail.vue"),
				meta: {
					title: "Project Details",
				},
			},
		],
	},
	{
		path: "/warehouseOrders",
		name: "Warehouse Orders",
		component: () => import("../views/warehouseOrders/warehouseOrders.vue"),
		meta: {
			title: "Warehouse Orders",
		},
	},
	{
		path: "/pickLists",
		name: "Pick Lists",
		component: () => import("../views/pickLists/pickLists.vue"),
		meta: {
			title: "Pick Lists",
			guard: ["allowFncViewWarehouseOrders"],
		},
		children: [
			{
				path: "pickListDetails/:id",
				component: () => import("../views/pickLists/pickListDetails/pickListDetails.vue"),
				meta: {
					title: "pickList Item Details"
				},
			},
		],
	},
	{
		path: "/inbound",
		name: "Inbound",
		component: () => import("../views/inbound/consignmentList.vue"),
		meta: {
			title: "Inbound",
			guard: ["allowFncViewIbound"]
		},
		children: [
			{
				path: "consignmentDetails/:id",
				component: () => import("../views/inbound/consignmentDetails/consignmentDetails.vue"),
				meta: {
					title: "Consignment Details",
				}
			}
		]
	},
	{
		path: "/kitting",
		name: "Kitting",
		component: () => import("../views/kitting/orderList/orderList.vue"),
		meta: {
			title: "Kitting",
			guard: ["allowFncViewKitting"]
		},
		children: [
			{
				path: "orderDetails/:id",
				component: () => import("../views/kitting/OrderDetails/orderDetails.vue"),
				meta: {
					title: "Order Details",
				}
			}
		]
	},
	// ****** Page Not Found -- Make sure it's your last route definition
	{
		path: "*",
		name: "Page Not Found",
		component: () => import("../views/pageNotFound/pageNotFound.vue"),
		meta: {
			title: "Page Not Found!",
		},
		beforeEnter: (to, from, next) => {
			if(to.path === "/index.html") {
				next(to.hash.replace("#", ""))
			}
			// only if path has a value after the forward slash ("/")
			if (to.path !== "/") {
				next()
			}
		},
	},
	{
		path: "/utility",
		name: "Utility",
		component: () => import("@/app/views/utility/driverLocationUtility.vue")

	}
]

const router = new VueRouter({
	mode: 'history',
	routes,
})

export default router
