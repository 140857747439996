import Vue from "vue"
import moment from "moment"
import indexedDB from "./../../Helpers/indexedDBHelpers/indexedDBApiRequest"
import getDirections from "./../../Helpers/mapServices/getDirections"
import deferredPromise from "./../../Helpers/deferredPromise"

function nearestPastMinutes(interval, someMoment) {
	const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval
	return someMoment.clone().minute(roundedMinutes).second(0)
}
function formatDateTimetoDate(date) {
	return moment(date).format("YYYY-MM-DD")
}

let _negativeId = 0
const getNewId = () => --_negativeId
const convertArrayToObject = (array, key) => {
	if (!(Array.isArray(array) && array.length > 0)) {
		return {}
	}

	return array.reduce((acc, item) => {
		acc[item[key]] = item
		return acc
	}, {})
}
const setTripContractFromVehicleContract = (trip, vehicleContract, tripLegs) => {
	if (!(trip && vehicleContract)) {
		return null
	}

	const vehicleInfo = {
		activeContractId: vehicleContract.contractId,
		vehicleId: vehicleContract.vehicleId,
		vehicleNumber: vehicleContract.vehicleNumber,
		vehicleDisplayNumber: vehicleContract.vehicleDisplayNumber,
		driverId: vehicleContract.driverId,
		driverFirstName: vehicleContract.driverFirstName,
		driverLastName: vehicleContract.driverLastName,
		driverPhoneNumber: vehicleContract.driverPhoneNumber,
	}

	if (Array.isArray(tripLegs) && tripLegs.length > 0) {
		tripLegs.forEach((tl) => {
			tl.contractId = vehicleContract.contractId
		})
	}

	return Object.assign(trip, vehicleInfo)
}

/**
 * calling with `tripLegStartTimeStamp` will create a leg with `tripLegStartTimeStamp` as the loadingTime
 * and calling with `dispatchTimeStamp` will create a leg with `dispatchTimeStamp` as the dispatchTime.
 * e.g.: when you have a slot call with `dispatchTimeStamp` and when you have a previous leg call with
 * `tripLegStartTimeStamp` as the previous leg's scheduledEndTime
 * @param {Object} tripLegParams
 * @param {Number} tripLegParams.tripId
 * @param {string} tripLegParams.tripLegStartTimeStamp TIMESTAMP
 * @param {string} tripLegParams.dispatchTimeStamp TIMESTAMP
 * @param {outboundWarehouse} tripLegParams.outboundWarehouse object
 */
const createTripLeg = ({ tripId, tripLegStartTimeStamp, dispatchTimeStamp, outboundWarehouse, isReverseTripLeg, isPickupAvailable, isDroppingAvailable }) => {
	if (!(tripId && (tripLegStartTimeStamp || dispatchTimeStamp) && outboundWarehouse)) {
		return null
	}

	// startTime = scheduledDispatchTime - loadingTime
	// endTime   = scheduledArrivalTime  + unloadingTime

	let scheduledStartMoment = tripLegStartTimeStamp ? moment(tripLegStartTimeStamp) : null
	let scheduledDispatchMoment = dispatchTimeStamp ? moment(dispatchTimeStamp) : null

	if (tripLegStartTimeStamp) {
		dispatchTimeStamp = null
		scheduledDispatchMoment = scheduledStartMoment.clone().add({ minutes: outboundWarehouse.loadingTimeInMinutes || 0 })
	} else {
		tripLegStartTimeStamp = null
		scheduledStartMoment = scheduledDispatchMoment.clone().add({ minutes: -1 * (outboundWarehouse.loadingTimeInMinutes || 0) })
	}

	const arrivalTimeInMinutes = outboundWarehouse.timeInMinutes
	const scheduledArrivalMoment = moment(scheduledDispatchMoment.format()).add({
		minutes: arrivalTimeInMinutes,
	})

	const scheduledEndMoment = scheduledArrivalMoment.clone().add({ minutes: outboundWarehouse.unloadingTimeInMinutes || 0 })

	const newTripLegObj = {
		tripLegId: getNewId(),
		tripId: tripId,
		outboundId: outboundWarehouse.outboundId,
		contractVehicleDriverId: null,
		legStatusId: 0,
		stockTypeIds: [],
		stockSubCategoryIds: [],
		warehouseOrderIds: [],
		scheduledDispatchTime: scheduledDispatchMoment.format(),
		scheduledArrivalTime: scheduledArrivalMoment.format(),
		scheduledStartTime: scheduledStartMoment.format(),
		scheduledEndTime: scheduledEndMoment.format(),
		inverseOutboundId: outboundWarehouse.inverseOutboundId,
		fromWarehouseId: outboundWarehouse.fromWarehouseId,
		fromWarehouseName: outboundWarehouse.fromWarehouseName,
		fromWarehouseShortName: outboundWarehouse.fromWarehouseShortName,
		toWarehouseId: outboundWarehouse.toWarehouseId,
		toWarehouseName: outboundWarehouse.toWarehouseName,
		toWarehouseShortName: outboundWarehouse.toWarehouseShortName,
		isReverseTripLeg: isReverseTripLeg,
		isPickupAvailable: isPickupAvailable,
		isDroppingAvailable: isDroppingAvailable,
	}

	return newTripLegObj
}

const updateTripLegBoundDetails = ({ tripLeg, tripLegStartTimeStamp, outboundWarehouse }) => {
	if (!(tripLeg && tripLegStartTimeStamp && outboundWarehouse)) {
		return null
	}

	const scheduledStartMoment = moment(tripLegStartTimeStamp)
	const scheduledDispatchMoment = scheduledStartMoment.clone().add({ minutes: outboundWarehouse.loadingTimeInMinutes || 0 })
	const scheduledArrivalMoment = moment(scheduledDispatchMoment.format()).add({
		minutes: outboundWarehouse.timeInMinutes || 0,
	})
	const scheduledEndMoment = scheduledArrivalMoment.clone().add({ minutes: outboundWarehouse.unloadingTimeInMinutes || 0 })

	tripLeg.outboundId = outboundWarehouse.outboundId
	tripLeg.scheduledDispatchTime = scheduledDispatchMoment.format()
	tripLeg.scheduledArrivalTime = scheduledArrivalMoment.format()
	tripLeg.scheduledStartTime = scheduledStartMoment.format()
	tripLeg.scheduledEndTime = scheduledEndMoment.format()
	tripLeg.inverseOutboundId = outboundWarehouse.inverseOutboundId
	tripLeg.fromWarehouseId = outboundWarehouse.fromWarehouseId
	tripLeg.fromWarehouseName = outboundWarehouse.fromWarehouseName
	tripLeg.fromWarehouseShortName = outboundWarehouse.fromWarehouseShortName
	tripLeg.toWarehouseId = outboundWarehouse.toWarehouseId
	tripLeg.toWarehouseName = outboundWarehouse.toWarehouseName
	tripLeg.toWarehouseShortName = outboundWarehouse.toWarehouseShortName

	return tripLeg
}

export default {
	namespaced: true,
	state: {
		outboundWarehouses: null,
		stockTypes: null,
		tripTypes: null,
		trips: null,
		tripLegs: null,
		routes: null,
		hasOutboundByWarehouseId: {},
		tripSubmissionState: null,
		vehicleContracts: null,
		cellIntervalInMinutes: 15,
		cellPxSize: 45,
		tripIdInPanel: null,
		openMapView: false,
		showMap: false,
		tripIdInMap: null,
		routeCode:null,
		crateDetails: {},
		selectedContractId: null
	},
	mutations: {
		setTripsData(state, payload) {
			if (payload.trips) {
				state.trips = payload.trips.reduce((acc, trip) => {
					acc[trip.tripId] = { err: null, response: trip, status: "success" }
					return acc
				}, {})
			}
			if (payload.tripLegs) {
				state.tripLegs = payload.tripLegs.reduce(
					(acc, tripLeg) => {
						// also associate stocktypes & stocktype categories to trip legs
						if (!tripLeg.stockTypeIds) {
							tripLeg.stockTypeIds = []
						}
						if (!tripLeg.tripLegStockTypes) {
							tripLeg.tripLegStockTypes = {}
						}
						;(payload.tripLegStockTypes || [])
							.filter((tlst) => tlst.tripLegId === tripLeg.tripLegId)
							.forEach((tlst) => {
								// Settting stocktype IDs
								tripLeg.stockTypeIds.push(tlst)

								// grouping stocktypes
								if (!tripLeg.tripLegStockTypes[tlst.stockCategoryName]) {
									tripLeg.tripLegStockTypes[tlst.stockCategoryName] = []
								}
								tripLeg.tripLegStockTypes[tlst.stockCategoryName].push(tlst.stockInvGroupName)
							})

						// also associate trip leg ids to trip as well
						if (state.trips && state.trips[tripLeg.tripId.toString()] && state.trips[tripLeg.tripId.toString()].response) {
							if (!state.trips[tripLeg.tripId.toString()].response.tripLegs) {
								state.trips[tripLeg.tripId.toString()].response.tripLegs = []
							}
							state.trips[tripLeg.tripId.toString()].response.tripLegs.push(tripLeg.tripLegId)
						}

						acc.response[tripLeg.tripLegId] = tripLeg
						return acc
					},
					{ err: null, response: {}, status: "success" }
				)
			}
		},
		setTripFormMetadata(state, payload) {
			if (payload.outboundWarehouses) {
				state.outboundWarehouses = {
					err: null,
					response: convertArrayToObject(payload.outboundWarehouses, "outboundId"),
					status: "success",
				}
			}
			if (payload.stockTypes) {
				state.stockTypes = payload.stockTypes
			}
			if (payload.tripTypes) {
				state.tripTypes = convertArrayToObject(payload.tripTypes, "tripTypeId")
			}
			if (payload.routes) {
				state.routes = payload.routes
			}
		},
		gettingVehicleContracts(state) {
			state.vehicleContracts = { err: null, response: {}, status: "loading" }
		},
		setVehicleContracts(state, payload) {
			if (payload.vehicleContracts) {
				state.vehicleContracts = {
					err: null,
					response: convertArrayToObject(payload.vehicleContracts, "contractId"),
					status: "success",
				}
			}
		},
		setContract(state, payload) {
			if (!(state.vehicleContracts && state.vehicleContracts.response)) {
				return
			}

			Vue.set(state.vehicleContracts.response, payload.contractId, payload)
		},
		setTrip(state, payload) {
			if (!(payload && payload.response && payload.response.tripId)) {
				return
			}
			if (!state.trips) {
				state.trips = {}
			}
			Vue.set(state.trips, payload.response.tripId, payload)
		},
		setTripLeg(state, payload) {
			if (!(payload && payload.tripLegId)) {
				return
			}
			if (!(state.tripLegs && state.tripLegs.response)) {
				state.tripLegs = { err: null, response: {}, status: "success" }
			}
			Vue.set(state.tripLegs.response, payload.tripLegId, payload)
		},
		removeTripLeg(state, tripLegId) {
			if (state.tripLegs && state.tripLegs.response && state.tripLegs.response[tripLegId.toString()]) {
				Vue.delete(state.tripLegs.response, tripLegId.toString())
			}
		},
		removeTrip(state, tripId) {
			if (state.trips && state.trips[tripId.toString()]) {
				const { response } = state.trips[tripId.toString()]
				if (Array.isArray(response.tripLegs)) {
					response.tripLegs.forEach((tlid) => {
						Vue.delete(state.tripLegs.response, tlid.toString())
					})
				}
				Vue.delete(state.trips, tripId.toString())
			}
		},
		setTripIdInPanel(state, tripId) {
			state.tripIdInPanel = tripId
		},
		setOutboundWarehouse(state, outboundWarehousesArray) {
			if (!Array.isArray(outboundWarehousesArray)) {
				return
			}

			if (!state.outboundWarehouses.response) {
				state.outboundWarehouses = {
					err: null,
					response: outboundWarehousesArray,
					status: "success",
				}
			} else {
				for (let index = 0; index < outboundWarehousesArray.length; index++) {
					const ow = outboundWarehousesArray[index]
					Vue.set(state.outboundWarehouses.response, ow.outboundId, ow)
				}
			}
		},
		setHasOutboundByWarehouseId(state, warehouseId) {
			Vue.set(state.hasOutboundByWarehouseId, warehouseId, true)
		},
		setCrateDetails(state, { crateData, tripId, newlyAdded }) {
			if (newlyAdded) {
				if (!state.crateDetails[tripId]) {
					Vue.set(state.crateDetails, tripId, crateData)
				} else if (state.crateDetails[tripId]) {
					const existedCrateStoreData = JSON.parse(JSON.stringify(state.crateDetails[tripId] || ""))
					if (existedCrateStoreData === "") {
						Vue.set(state.crateDetails, tripId, crateData)
					} else if (!existedCrateStoreData.length) {
						state.crateDetails[tripId] = [...crateData]
					} else {
						let newList = existedCrateStoreData.filter(c => !crateData.some(ct => (c.fromWarehouseId === ct.fromWarehouseId && c.toWarehouseId === ct.toWarehouseId && c.tripLegId === ct.tripLegId)))
						state.crateDetails[tripId] = [...newList, ...crateData]
					}
				}
			} else {
				if (!state.crateDetails[tripId]) {
					Vue.set(state.crateDetails, tripId, crateData)
				} else {
					state.crateDetails[tripId] = [...new Set([...crateData, ...state.crateDetails[tripId]])]
				}
			}
		},
		removeCrateDetails(state, tripId) {
			if (state.crateDetails && state.crateDetails[tripId.toString()]) {
				Vue.delete(state.crateDetails, tripId.toString())
			}
		},
		setSelectedContractId(state, contractId) {
			state.selectedContractId = contractId
		}
	},
	actions: {
		async updateTripsList({ commit, dispatch, rootState }, { warehouseId, date, tenantId, tripDetails }) {
			const apiMethod = rootState.user && rootState.user.response && rootState.user.response.userTypeId === 1 ? "getTripsListForWarehouseEmployeeDelta" : "getTripsListForDriver"
			const updateDB = await indexedDB.handleTripUpdate(apiMethod, { warehouseId, date, tenantId, userId: rootState.user.response.userId }, tripDetails)
			if (updateDB) {
				dispatch("getTripsList", { warehouseId, date, tenantId })
			}
		},
		async getTripsList({ commit, dispatch, rootState }, { warehouseId, date, tenantId }) {
			const apiMethod = rootState.user && rootState.user.response && rootState.user.response.userTypeId === 1 ? "getTripsListForWarehouseEmployeeDelta" : "getTripsListForDriver"

			const requestParams = { warehouseId, date, tenantId }

			if (apiMethod === "getTripsListForWarehouseEmployeeDelta") {
				let data = await indexedDB.indexedDBFetchApi(apiMethod, requestParams, rootState.user.response.userId)

				if (data && data.response && data.status === "success") {
					const response = data.response
					const mergeTripStatusWithTripLeg = []
					response.tripLegs.forEach((item) => {
						response.tripTrackingStatus.filter((i) => {
							if (i.tripLegId == item.tripLegId && i.tripId == item.tripId) {
								const result = Object.assign(i, item)
								mergeTripStatusWithTripLeg.push(result)
							}
						})
					})
					response.trips.forEach((trip) => {
						if (tenantId === "698d51a19d8a121ce581499d7b701668") {
							trip.isExpanded = false
						}
						trip.vehicleTrackers = response.vehicleTrackers.find((vehicleTracker) => {
							return trip.vehicleId == vehicleTracker.vehicleId
						})
						trip.trackingAlerts = response.trackingAlerts.filter(ta => ta.tripId === trip.tripId)
					})
					response.tripLegs = mergeTripStatusWithTripLeg
					commit("setTripsData", response)
				} else {
					console.log("An error occured while trying to fetch trip list!", data)
				}
			} else {
				let data = await dispatch("fetchApiRequest", { apiMethod, requestParams }, { root: true })
				if (data && data.response && data.status === "success") {
					const response = data.response
					commit("setTripsData", response)
				} else {
					console.log("An error occured while trying to fetch trip list!", data)
				}
			}
		},
		async getTripFormMetadataDelta({ commit, rootState }, { tenantId, warehouseId }) {
			let requestParams = { tenantId, warehouseId }

			let data = await indexedDB.indexedDBFetchApi("getTripFormMetadataDelta", requestParams, rootState.user.response.userId)
			const response = data && data.response ? data.response : {}

			commit("setTripFormMetadata", response)
			commit("setHasOutboundByWarehouseId", response.contextWarehouse.warehouseId)
			commit("setCurrentWarehouse", response.contextWarehouse, { root: true })
		},
		async getSchedulerVehicleContracts({ commit, dispatch, rootState }, { warehouseId, date, limit, offset, datesArray, alsoIncludeContractId }) {
			// todo: check if datesArray has valid fields (startDate, endDate)
			commit("gettingVehicleContracts")

			let tenantId = rootState.currentTenantId
			if (Array.isArray(datesArray) && datesArray.length > 0) {
				const data = await dispatch(
					"fetchApiRequest",
					{
						apiMethod: "getSchedulerVehicleContracts",
						requestParams: {
							warehouseId,
							date,
							limit,
							offset,
							datesArray,
							alsoIncludeContractId,
							tenantId,
						},
					},
					{ root: true }
				)

				const vehicleContractsResponse = data && data.response ? data.response : {}
				commit("setVehicleContracts", vehicleContractsResponse)
			} else {
				let apiMethod = "getSchedulerVehicleContractsDelta"
				let requestParams = {
					warehouseId,
					date,
					limit,
					offset,
					datesArray,
					alsoIncludeContractId,
					tenantId,
				}

				const data = await indexedDB.indexedDBFetchApi(apiMethod, requestParams, rootState.user.response.userId)
				const vehicleContractsResponse = data && data.response ? data.response : {}
				commit("setVehicleContracts", vehicleContractsResponse)
			}
		},
		async createTrip({ state, commit, dispatch, rootState, rootGetters }, { tripId, persistAfterSubmit }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip) {
				console.log("Could not find trip in store to create new trip")
				return
			}
			if (tripId > 0) {
				console.log("Trip already created")
				return
			}

			const tripRequest = {
				tripId: trip.tripId,
				tripTypeId: trip.tripTypeId,
				scheduledInWarehouseId: trip.scheduledInWarehouseId,
				scheduledForDate: formatDateTimetoDate(trip.scheduledForDate),
				contractId: trip.activeContractId,
				clientTripReferenceId: trip.clientTripReferenceId,
				routeId: trip.routeId,
				tripLegs: trip.tripLegs.map((ledId) => {
					let tl = state.tripLegs.response[ledId.toString()]
					return {
						tripLegId: tl.tripLegId,
						tripId: tl.tripId,
						outboundId: tl.outboundId,
						scheduledStartTime: tl.scheduledStartTime,
						scheduledDispatchTime: tl.scheduledDispatchTime,
						scheduledArrivalTime: tl.scheduledArrivalTime,
						scheduledEndTime: tl.scheduledEndTime,
						stockTypeIds: (tl.stockTypeIds || []).map((sc) => sc.stockTypeId).filter((id) => (id ? true : false)),
						stockSubCategoryIds: (tl.stockSubCategoryIds || []).map((sc) => sc.stockSubCategoryId).filter((id) => (id ? true : false)),
						warehouseOrderIds: tl.warehouseOrderIds || [],
						isReverseTripLeg: tl.isReverseTripLeg || 0,
						isPickupAvailable: tl.isPickupAvailable || (rootGetters.showPickAndDelivery ? 0 : null),
						isDroppingAvailable: tl.isDroppingAvailable || (rootGetters.showPickAndDelivery ? 0 : null),
					}
				}),
				tenantId: rootState.currentTenantId,
			}
			if (rootState.tenantConfigurationJSON?.Trip.gatePassCratesEntry) {
				tripRequest.crateDetails = state.crateDetails[trip.tripId]
			}

			state.tripSubmissionState = "inprogress"
			// Bypass Endpoint for this tenant 'a5771bce93e200c36f7cd9dfd0e5deaa'
			const { err, response, status } = await dispatch(
				"fetchApiRequest",
				{
					// apiMethod: rootState.currentTenantId === 'a5771bce93e200c36f7cd9dfd0e5deaa' ? 'createMilkTypeTrip' : "createTrip",
					apiMethod: "createTrip",
					requestParams: tripRequest,
				},
				{ root: true }
			)
			// return

			state.tripSubmissionState = null
			if (err) {
				Vue.toasted.error(err.errno ? err.errmsg : "An error occured while trying to save trip")
				console.log("Could not create trip", err)
				return
			}

			// delete ephemeral trip and it's legs
			trip.tripLegs.map((tl) => commit("removeTripLeg", tl))
			commit("removeTrip", trip.tripId)
			commit("removeCrateDetails", trip.tripId)
			dispatch("setTripDetail", { err, response, status })
			commit("setTripIdInPanel", response.tripId)

			//finally close popup
			if (!persistAfterSubmit) {
				dispatch("cancelTripPanel", { tripId: response.tripId })
				Vue.toasted.success("Trip details have been saved successfully.")
			}

			return response
		},
		async editTrip({ state, commit, dispatch, rootState, rootGetters }, { tripId, persistAfterSubmit }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip) {
				console.log("Could not find trip in store to create new trip")
				return
			}
			if (tripId < 0) {
				console.log("Could not update trip that isn't created")
				return
			}

			const tripRequest = {
				tripId: trip.tripId,
				tripTypeId: trip.tripTypeId,
				scheduledInWarehouseId: trip.scheduledInWarehouseId,
				scheduledForDate: trip.scheduledForDate,
				contractId: trip.activeContractId,
				clientTripReferenceId: trip.clientTripReferenceId,
				routeId: trip.routeId,
				tripLegs: trip.tripLegs
					.filter((legId) => state.tripLegs.response[legId.toString()].legStatusId !== 3)
					.map((ledId) => {
						let tl = state.tripLegs.response[ledId.toString()]
						return {
							tripLegId: tl.tripLegId,
							tripId: tl.tripId,
							outboundId: tl.outboundId,
							scheduledStartTime: tl.scheduledStartTime,
							scheduledDispatchTime: tl.scheduledDispatchTime,
							scheduledArrivalTime: tl.scheduledArrivalTime,
							scheduledEndTime: tl.scheduledEndTime,
							stockTypeIds: (tl.stockTypeIds || []).map((sc) => sc.stockTypeId).filter((id) => (id ? true : false)),
							stockSubCategoryIds: (tl.stockSubCategoryIds || []).map((sc) => sc.stockSubCategoryId).filter((id) => (id ? true : false)),
							warehouseOrderIds: tl.warehouseOrderIds || [],
							isReverseTripLeg: tl.isReverseTripLeg || 0,
							isPickupAvailable: tl.isPickupAvailable || (rootGetters.showPickAndDelivery ? 0 : null),
							isDroppingAvailable: tl.isDroppingAvailable || (rootGetters.showPickAndDelivery ? 0 : null),
						}
					}),
				tenantId: rootState.currentTenantId,
			}
			if (rootState.tenantConfigurationJSON?.Trip.gatePassCratesEntry) {	//Atlas code
				tripRequest.crateDetails = state.crateDetails[trip.tripId]
			}

			state.tripSubmissionState = "inprogress"
			const { err, response, status } = await dispatch(
				"fetchApiRequest",
				{
					apiMethod: "editTrip",
					requestParams: tripRequest,
				},
				{ root: true }
			)

			state.tripSubmissionState = null
			if (err) {
				console.log(`err:::`,  err)
				Vue.toasted.error(err.errno ? err.errmsg : "An error occured while trying to edit trip")
				console.log("Could not edit trip", err)
				return
			}

			// delete ephemeral trip and it's legs
			trip.tripLegs.forEach((tl) => commit("removeTripLeg", tl))
			commit("removeTrip", trip.tripId)
			commit("removeCrateDetails", trip.tripId)
			dispatch("setTripDetail", { response, status })

			//finally close popup
			if (!persistAfterSubmit) {
				dispatch("cancelTripPanel", { tripId: trip.tripId })
				Vue.toasted.success("Trip details have been updated successfully.")
			}

			return response
		},
		async cancelTrip({ state, dispatch, rootState, commit }, { tripId, tripCancelReasonId, description }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip || tripId < 0) {
				Vue.toasted.error(err.errno ? err.errmsg : "Could not find trip in store to cancel the trip")
				return
			}

			state.tripSubmissionState = "inprogress"
			const { err, response, status } = await dispatch(
				"fetchApiRequest",
				{
					apiMethod: "cancelTrip",
					requestParams: {
						tripId: tripId,
						tripCancelReasonId: tripCancelReasonId,
						description: description,
						tenantId: rootState.currentTenantId,
					},
				},
				{ root: true }
			)

			state.tripSubmissionState = null
			if (err) {
				Vue.toasted.error(err.errno ? err.errmsg : "An error occured while trying to cancel trip")
				console.log("Could not cancel trip", err)
				return
			} else {
				Vue.toasted.success("Trip details have been cancelled successfully.")
			}

			dispatch("setTripDetail", { response, status })

			//finally close popup
			dispatch("cancelTripPanel", { tripId: trip.tripId })
			commit("removeCrateDetails", trip.tripId)
		},
		async getTripDetail({ dispatch, rootState }, { tripId }) {
			if (!(tripId && tripId > 0)) {
				return
			}

			const apiResponse = await dispatch(
				"fetchApiRequest",
				{
					apiMethod: "v1/getTripDetail",
					requestParams: { tripId, tenantId: rootState.currentTenantId },
				},
				{ root: true }
			)

			if (apiResponse.err) {
				Vue.toasted.show(apiResponse.err.errmsg ? apiResponse.err.errmsg : apiResponse.err)
				console.log("Could not get trip", apiResponse.err)
			} else if (apiResponse.response) {
				dispatch("setTripDetail", JSON.parse(JSON.stringify(apiResponse)))
			}
			return apiResponse
		},
		setTripDetail({ commit, rootState }, { err, response }) {
			response.tripLegs.forEach((tl) => {
				// associate warehouse orders for legs
				if (response?.warehouseOrdersJSON?.length) {
					let tripLegsOrders = response.warehouseOrdersJSON.filter((wo) => wo.tripLegId === tl.tripLegId)
					tl.warehouseOrdersJSON = tripLegsOrders
					tl.warehouseOrderIds = tripLegsOrders.map((wo) => wo.warehouseOrderId)
				} else {
					tl.warehouseOrdersJSON = []
					tl.warehouseOrderIds = []
				}

				// also associate stocktypes & stocktype categories to trip legs
				if (!tl.stockTypeIds) {
					tl.stockTypeIds = []
				}
				if (!tl.tripLegStockTypes) {
					tl.tripLegStockTypes = {}
				}

				(response.tripLegStockTypes || [])
					.filter((tlst) => tlst.tripLegId === tl.tripLegId)
					.forEach((tlst) => {
						// Settting stocktype IDs
						tl.stockTypeIds.push(tlst)

						// Grouping stock types
						if (!tl.tripLegStockTypes[tlst.stockCategoryName]) {
							tl.tripLegStockTypes[tlst.stockCategoryName] = []
						}
						tl.tripLegStockTypes[tlst.stockCategoryName].push(tlst.stockInvGroupName)
					})
				commit("setTripLeg", tl)
			})

			// For atlas, update crate details
			if (rootState.tenantConfigurationJSON?.Trip.gatePassCratesEntry) {
				const tripLegs = response.tripLegs.map(tl => {
					const  gp = response.gatePass.find(gp => gp.tripLegId === tl.tripLegId)
					if (gp) {
						return { gatePassId: gp.gatePassId, lrNumber: gp.lrNumber, ...tl }
					} else return gp
				}).filter(f => !!f)
				const tripLegCrates = response.tripLegCrates.map(tlc => {
					const trip = tripLegs.find(tl => tl.gatePassId === tlc.gatePassId)
					return {
						...tlc,
						tripId: trip?.tripId,
						tripLegId: trip?.tripLegId,
						fromWarehouseId: trip.fromWarehouseId,
						toWarehouseId: trip.toWarehouseId,
						lr: trip.lrNumber,
						[(tlc.crateTypeName || "").toLowerCase()]: tlc.unitPrice
					}
				})
				if (!rootState.tripTrackingStore.crateDetails[response.tripLegs[0].tripId]?.length) {
					commit("setCrateDetails", { crateData: tripLegCrates, tripId: response.tripLegs[0].tripId, newlyAdded: false})
				}
			}

			// also associate trip leg ids to trip as well
			response.tripLegs = response.tripLegs.map((tl) => tl.tripLegId)

			// also set veicle tracker
			response.vehicleTrackers = Array.isArray(response.vehicleTrackers) && response.vehicleTrackers[0] ? response.vehicleTrackers[0] : null

			commit("setTrip", { err, response, status: "success" })
			commit("setSelectedContractId", response.activeContractId)
		},
		setVehicleContractToTrip({ state, commit }, { tripId, vehicleContract }) {
			const tripState = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()])) : null
			if (!tripState) {
				return
			}
			const trip = tripState.response
			const tripLegs = Array.isArray(trip.tripLegs) ? trip.tripLegs.map((legId) => state.tripLegs.response[legId.toString()]).filter((x) => (x ? true : false)) : []

			// set trip status as loading
			setTripContractFromVehicleContract(trip, vehicleContract, tripLegs) //

			commit("setTrip", tripState)
			tripLegs.forEach((tl) => commit("setTripLeg", tl))
		},
		changeTripType({ state, commit }, { trip, tripType, includeReverseTripLegForMultiCityTrip = true }) {
			// only change trip type if it's an ephermal trip
			if (!(trip && tripType && trip.tripId <= 0)) {
				return
			}

			trip = JSON.parse(JSON.stringify(trip))

			trip.tripTypeId = tripType.tripTypeId

			// reset route if tripType changed to one way or round trip
			if (tripType.tripTypeId === 1 || tripType.tripTypeId === 2) {
				trip.routeId = null
			}

			if (tripType.tripTypeId === 1 || tripType.tripTypeId === 2 || tripType.tripTypeId === 3) {
				// remove all legs except the first leg
				trip.tripLegs.splice(1, trip.tripLegs.length).forEach((tripLegId) => commit("removeTripLeg", tripLegId))
			}

			if (tripType.tripTypeId === 2 || (tripType.tripTypeId === 3 && includeReverseTripLegForMultiCityTrip)) {
				// add new trip leg from destination to source
				const sourceToDestTripLeg = state.tripLegs.response[trip.tripLegs[0].toString()]
				const sourceToDestOutbound = state.outboundWarehouses.response[sourceToDestTripLeg.outboundId.toString()]
				const destToSourceOutbound = state.outboundWarehouses.response[sourceToDestOutbound.inverseOutboundId.toString()]

				const destToSourceTripLeg = createTripLeg({
					tripId: trip.tripId,
					tripLegStartTimeStamp: sourceToDestTripLeg.scheduledEndTime,
					dispatchTimeStamp: null,
					outboundWarehouse: destToSourceOutbound,
					isReverseTripLeg: 0,
					isPickupAvailable: 0,
					isDroppingAvailable: 0,
				})

				trip.tripLegs.push(destToSourceTripLeg.tripLegId)

				commit("setTripLeg", destToSourceTripLeg)
			}
			commit("setTrip", { err: null, status: "success", response: trip })
		},
		addTripLeg({ state, commit }, { tripId, outboundWarehouse, isReverseTripLeg, isPickupAvailable, isDroppingAvailable }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip) {
				// console.log("Could not find trip to add new leg");
				return
			}

			if (!(outboundWarehouse && outboundWarehouse.timeInMinutes > 0)) {
				console.log("Missing timeInMinutes for outbound warehouse")
				return
			}

			if (trip.tripLegs.length > 0) {
				// get last leg
				const lastLeg = state.tripLegs.response[trip.tripLegs[trip.tripLegs.length - 1].toString()]

				if (lastLeg.toWarehouseId !== outboundWarehouse.fromWarehouseId) {
					console.log("Last leg doesn't end where this leg wants to start")
					return
				}

				const newTripLeg = createTripLeg({
					tripId: trip.tripId,
					tripLegStartTimeStamp: lastLeg.scheduledEndTime,
					dispatchTimeStamp: null,
					outboundWarehouse,
					isReverseTripLeg,
					isPickupAvailable,
					isDroppingAvailable,
				})

				trip.tripLegs.push(newTripLeg.tripLegId)

				commit("setTripLeg", newTripLeg)

				commit("setTrip", { err: null, status: "success", response: trip })
			} else {
				// this will be the first leg
				const newTripLeg = createTripLeg({
					tripId: trip.tripId,
					tripLegStartTimeStamp: null,
					dispatchTimeStamp: trip.scheduledForDate,
					outboundWarehouse,
					isReverseTripLeg,
					isPickupAvailable,
					isDroppingAvailable,
				})

				trip.tripLegs.push(newTripLeg.tripLegId)
				commit("setTripLeg", newTripLeg)

				// if this is the first leg and the trip is of type round trip then add the return trip leg as well
				if (trip.tripTypeId === 2) {
					const inverseOutboundWarehouse = state.outboundWarehouses.response[outboundWarehouse.inverseOutboundId.toString()]
					const returnTripLeg = createTripLeg({
						tripId: trip.tripId,
						tripLegStartTimeStamp: newTripLeg.scheduledEndTime,
						dispatchTimeStamp: null,
						outboundWarehouse: inverseOutboundWarehouse,
						isReverseTripLeg,
						isPickupAvailable,
						isDroppingAvailable,
					})

					trip.tripLegs.push(returnTripLeg.tripLegId)
					commit("setTripLeg", returnTripLeg)
				}

				commit("setTrip", { err: null, status: "success", response: trip })
			}
		},
		removeTripLeg({ state, commit, dispatch, getters, rootState }, { tripId, tripLegId }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip) {
				console.log("Could not find trip to remove leg")
				return
			}

			if ((trip.tripTypeId === 1 || trip.tripTypeId === 3) && trip.tripLegs.indexOf(tripLegId) === 0) {
				// don't remove first leg
				return
			} else if (trip.tripTypeId === 2 && trip.tripLegs.indexOf(tripLegId) <= 1) {
				// don't remove second leg if it's a round trip
				return
			}

			// Check if removed leg is last leg
			const isLastLeg = trip.tripLegs.indexOf(tripLegId) === trip.tripLegs.length - 1
			if (isLastLeg) {
				// If remove leg is last leg remove trip leg and update trip obj
				trip.tripLegs.splice(trip.tripLegs.indexOf(tripLegId), 1)
				commit("removeTripLeg", tripLegId)
				commit("setTrip", { err: null, status: "success", response: trip })
			} else {
				const removeLeg = (state.tripLegs.response || {})[tripLegId.toString()] ? JSON.parse(JSON.stringify((state.tripLegs.response || {})[tripLegId.toString()])) : null
				const immediateNextLegId = trip.tripLegs[trip.tripLegs.indexOf(tripLegId) + 1]
				const immediateNextLeg = (state.tripLegs.response || {})[immediateNextLegId.toString()]
					? JSON.parse(JSON.stringify((state.tripLegs.response || {})[immediateNextLegId.toString()]))
					: null

				// if removed leg's FROM location is immediate next leg's TO location show error
				if (removeLeg.fromWarehouseId === immediateNextLeg.toWarehouseId) {
					Vue.toasted.error("Removed leg's 'From' location can't be next leg's 'To' location!")
					return
				}

				// check if src and dest has metrics available, add if not available
				const src = (rootState.warehousesList || []).find((w) => w.warehouseId === removeLeg.fromWarehouseId)
				const dest = (rootState.warehousesList || []).find((w) => w.warehouseId === immediateNextLeg.toWarehouseId)

				let metricsDeferred = deferredPromise()
				let outboundWarehouse = getters.outboundWarehouses(src.warehouseId).find((o) => o.toWarehouseId === dest.warehouseId)

				if (outboundWarehouse) {
					metricsDeferred.resolve()
				} else {
					dispatch("addMetricsUsingMapsAPIService", { src, dest })
						.then((response) => {
							console.log("Metrics added successfully.", { src, dest })
							// set metrics response to store
							commit("setOutboundWarehouse", response)
							// set outboundWarehouse and resolve metrics
							outboundWarehouse = getters.outboundWarehouses(src.warehouseId).find((o) => o.toWarehouseId === dest.warehouseId)
							metricsDeferred.resolve()
						})
						.catch((error) => {
							Vue.toasted.error(error)
							metricsDeferred.reject()
						})
				}

				metricsDeferred.promise.then(() => {
					// remove leg and update the trip obj
					trip.tripLegs.splice(trip.tripLegs.indexOf(tripLegId), 1)
					commit("removeTripLeg", tripLegId)
					commit("setTrip", { err: null, status: "success", response: trip })

					// set removed leg's start time to immediate next leg
					const modifiedTripLeg = updateTripLegBoundDetails({
						tripLeg: immediateNextLeg,
						tripLegStartTimeStamp: removeLeg.scheduledStartTime,
						outboundWarehouse,
					})
					commit("setTripLeg", modifiedTripLeg)

					// set removed leg's FROM location value to immediate next leg's FROM
					dispatch("changeTripLegLocation", {
						tripId,
						tripLegId: immediateNextLegId,
						outboundWarehouse,
					})
				})
			}
		},
		changeTripLegDepartureDate({ state, commit }, { tripId, tripLegId, newDate }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip) {
				console.log("Could not find trip to change departure date")
				return
			}

			if (!(tripLegId && state.tripLegs.response[tripLegId.toString()])) {
				console.log("Could not find trip leg to change departure date")
				return
			}

			const modifiedTripLeg = state.tripLegs.response[tripLegId.toString()]
			const modifiedTripLegIndex = trip.tripLegs.indexOf(tripLegId)

			// Trip leg departure date cannot be less than previous leg's end time
			// unless this is the first leg.
			const minumumAllowedTimeStampMoment =
				modifiedTripLegIndex === 0
					? null
					: (() => {
							// get previous trip leg
							const prevTripLegId = trip.tripLegs[modifiedTripLegIndex - 1]
							const prevTripLeg = state.tripLegs.response[prevTripLegId.toString()]
							const modifiedTripLegOutbound = state.outboundWarehouses.response[modifiedTripLeg.outboundId.toString()]

							return moment(prevTripLeg.scheduledEndTime).add({
								minutes: modifiedTripLegOutbound.loadingTimeInMinutes || 0,
							})
					  })()

			if (modifiedTripLegIndex > 0) {
				if (moment(newDate).isBefore(minumumAllowedTimeStampMoment)) {
					console.log("Trip leg departure date cannot be less than previous leg's end time")
					return
				}
			} else if (modifiedTripLegIndex === 0 && tripId < 0) {
				// if this is the first leg of new trip, modifiy new time of trip as well
				trip.scheduledForDate = newDate
				commit("setTrip", { err: null, response: trip, status: "success" })
			}

			const differenceInMinutes = moment(newDate).diff(modifiedTripLeg.scheduledDispatchTime, "minutes")

			// add this differenceInMinutes to all trip legs right from the changed trip leg
			for (let index = modifiedTripLegIndex; index < trip.tripLegs.length; index++) {
				const currentTripLegId = trip.tripLegs[index]
				const currentTripLeg = JSON.parse(JSON.stringify(state.tripLegs.response[currentTripLegId.toString()]))

				currentTripLeg.scheduledDispatchTime = moment(currentTripLeg.scheduledDispatchTime).add({ minutes: differenceInMinutes }).format()
				currentTripLeg.scheduledArrivalTime = moment(currentTripLeg.scheduledArrivalTime).add({ minutes: differenceInMinutes }).format()
				currentTripLeg.scheduledStartTime = moment(currentTripLeg.scheduledStartTime).add({ minutes: differenceInMinutes }).format()
				currentTripLeg.scheduledEndTime = moment(currentTripLeg.scheduledEndTime).add({ minutes: differenceInMinutes }).format()

				commit("setTripLeg", currentTripLeg)
			}
		},
		changeTripLegLocation({ state, commit, dispatch, getters, rootState }, { tripId, tripLegId, outboundWarehouse }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			const tripLeg = (state.tripLegs.response || {})[tripLegId.toString()] ? JSON.parse(JSON.stringify((state.tripLegs.response || {})[tripLegId.toString()])) : null
			const tripLegIndex = trip.tripLegs.findIndex((ledId) => ledId === tripLegId)

			if (!(trip && tripLeg)) {
				console.log("Could not find trip and trip leg to update")
				return
			}
			if (!(outboundWarehouse && outboundWarehouse.timeInMinutes > 0)) {
				console.log("Missing timeInMinutes for outbound warehouse")
				return
			}

			// Check if modified leg is last leg
			const isLastLeg = tripLegIndex === trip.tripLegs.length - 1
			if (isLastLeg) {
				// if last leg just update modified leg
				dispatch("updateTripLegLocationAndDependencies", {
					trip,
					tripLeg,
					tripLegIndex,
					outboundWarehouse,
				})
			} else {
				// update all the following legs timmings as well with metrics dependency
				const immediateNextLegId = trip.tripLegs[tripLegIndex + 1]
				const immediateNextLeg = (state.tripLegs.response || {})[immediateNextLegId.toString()]
					? JSON.parse(JSON.stringify((state.tripLegs.response || {})[immediateNextLegId.toString()]))
					: null
				if (!immediateNextLeg) {
					console.log("Could not find immediate next trip leg to update")
					return
				}
				if (immediateNextLeg && outboundWarehouse.toWarehouseId === immediateNextLeg.toWarehouseId) {
					console.log("tripLeg dest cant be immediateNextLeg dest!")
					return
				}

				// If Metrics Available for the updated leg dest to next leg dest as the updated leg dest should replace next leg src
				// if trip is of type round trip then update the return trip leg as same as reverse
				let immediateNextLegOutboundWarehouse =
					trip.tripTypeId === 2
						? state.outboundWarehouses.response[outboundWarehouse.inverseOutboundId.toString()]
						: getters.outboundWarehouses(outboundWarehouse.toWarehouseId).find((ow) => ow.toWarehouseId === immediateNextLeg.toWarehouseId)

				if (immediateNextLegOutboundWarehouse) {
					dispatch("updateTripLegLocationAndDependencies", {
						trip,
						tripLeg,
						tripLegIndex,
						outboundWarehouse,
						immediateNextLeg,
						immediateNextLegOutboundWarehouse,
					})
				}
				// Else add metrics using Maps Distance API and updateTripLegLocationAndDependencies
				else {
					let src = (rootState.warehousesList || []).find((e) => e.warehouseId === outboundWarehouse.toWarehouseId)
					let dest = (rootState.warehousesList || []).find((e) => e.warehouseId === immediateNextLeg.toWarehouseId)
					if (!(src && dest)) {
						console.log("Could not find src and dest to add metrics!")
						return
					}
					dispatch("addMetricsUsingMapsAPIService", { src, dest })
						.then((response) => {
							commit("setOutboundWarehouse", response)
							immediateNextLegOutboundWarehouse = getters.outboundWarehouses(outboundWarehouse.toWarehouseId).find((ow) => ow.toWarehouseId === immediateNextLeg.toWarehouseId)
							dispatch("updateTripLegLocationAndDependencies", {
								trip,
								tripLeg,
								tripLegIndex,
								outboundWarehouse,
								immediateNextLeg,
								immediateNextLegOutboundWarehouse,
							})
							console.log("Metrics added successfully.", { src, dest })
						})
						.catch((error) => {
							Vue.toasted.error(error)
						})
				}
			}
		},
		updateTripLegLocationAndDependencies({ state, commit, getters }, { trip, tripLeg, tripLegIndex, outboundWarehouse, immediateNextLeg, immediateNextLegOutboundWarehouse }) {
			const modifiedTripLeg = updateTripLegBoundDetails({
				tripLeg,
				tripLegStartTimeStamp: tripLeg.scheduledStartTime,
				outboundWarehouse,
			})
			commit("setTripLeg", modifiedTripLeg)

			// Then update modified leg's immediate next leg src with modified leg's dest
			if (immediateNextLeg && immediateNextLegOutboundWarehouse) {
				const immediateNextTripLeg = updateTripLegBoundDetails({
					tripLeg: immediateNextLeg,
					tripLegStartTimeStamp: modifiedTripLeg.scheduledEndTime,
					outboundWarehouse: immediateNextLegOutboundWarehouse,
				})
				commit("setTripLeg", immediateNextTripLeg)

				// Then Update scheduled date times for following legs if exist
				let nextLegStart = immediateNextLeg.scheduledEndTime
				trip.tripLegs.forEach((legId, index) => {
					if (index > tripLegIndex + 1) {
						const leg = (state.tripLegs.response || {})[legId.toString()] ? JSON.parse(JSON.stringify((state.tripLegs.response || {})[legId.toString()])) : null
						if (!leg) {
							return
						}
						const outboundWarehouse = getters.outboundWarehouses(leg.fromWarehouseId).find((ow) => ow.toWarehouseId === leg.toWarehouseId)
						const updatedTripLeg = updateTripLegBoundDetails({
							tripLeg: leg,
							tripLegStartTimeStamp: nextLegStart,
							outboundWarehouse: outboundWarehouse,
						})
						nextLegStart = updatedTripLeg.scheduledEndTime
						commit("setTripLeg", updatedTripLeg)
					}
				})
			}
		},
		tryAndCreateNewTrip(
			{ commit, dispatch, rootState },
			{
				tripTypeId = rootState.tenantConfigurationJSON?.Trip?.defaultTripTypeId,
				slotObj,
				outboundWarehouse,
				vehicleContract,
				clientTripReferenceId,
				routeId,
				includeReverseTripLegForMultiCityTrip,
			}
		) {
			if (!(slotObj && outboundWarehouse && outboundWarehouse.timeInMinutes)) {
				return
			}

			const newTripObj = {
				tripId: getNewId(),
				scheduledInWarehouseId: rootState.currentWarehouseId,
				scheduledForDate: rootState.date + "T" + slotObj.slotStartTime + rootState.timezone,
				tripTypeId: 1,
				tripStatusId: 0,
				tripLegs: [],
				clientTripReferenceId: clientTripReferenceId,
				routeId: routeId,
			}

			// Set vehicle if available
			if (vehicleContract) {
				setTripContractFromVehicleContract(newTripObj, vehicleContract)
			}

			// Create Trip with One Leg (One Way Trip)
			const newTripLegObj = createTripLeg({
				tripId: newTripObj.tripId,
				tripLegStartTimeStamp: null,
				dispatchTimeStamp: newTripObj.scheduledForDate,
				outboundWarehouse,
				isReverseTripLeg: 0,
				isPickupAvailable: 0,
				isDroppingAvailable: 0,
			})
			newTripObj.tripLegs.push(newTripLegObj.tripLegId)
			commit("setTripLeg", newTripLegObj)
			commit("setTrip", { err: null, status: "success", response: newTripObj })

			// Add Legs If Round/Multi City Trip Selected
			if (tripTypeId === 2) {
				// Round
				dispatch("changeTripType", { trip: newTripObj, tripType: { tripTypeId: 2 } })
			} else if (tripTypeId === 3) {
				// Multi City
				dispatch("changeTripType", {
					trip: newTripObj,
					tripType: { tripTypeId: 3 },
					includeReverseTripLegForMultiCityTrip,
				})
			}
			commit("setTripIdInPanel", newTripObj.tripId)
		},
		cancelTripPanel({ state, commit, dispatch, rootState }, { tripId, getTrip }) {
			if (!tripId) {
				return
			}

			if (tripId > 0 && getTrip) {
				// delete ephemeral trip legs if added
				const tripLegIds = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)).tripLegs : []
				tripLegIds.filter((i) => i < 0).forEach((i) => commit("removeTripLeg", i))

				// then reset trip to revert unsaved changes by getting it from server
				console.log('this is from cancel method of store',tripId)

				dispatch("getTripDetail", { tripId })
			} else if (tripId < 0) {
				// delete ephemeral trip and it's legs
				commit("removeTrip", tripId)
			}
			commit("setTripIdInPanel", null)

			// also refresh vehicles
			dispatch("getSchedulerVehicleContracts", {
				warehouseId: rootState.currentWarehouseId,
				date: rootState.date,
			})
		},
		addMetricsUsingMapsAPIService({ dispatch, rootState, rootGetters }, { src, dest }) {
			return new Promise((resolve, reject) => {
				if (rootGetters.allowCreateWarehouseToWarehouseMetrics) {
					if (src && src.geofenceLat && src.geofenceLong && dest && dest.geofenceLat && dest.geofenceLong) {
						let coordinates = [
							{ lat: src.geofenceLat, lng: src.geofenceLong },
							{ lat: dest.geofenceLat, lng: dest.geofenceLong },
						]
						let addReverseDirections = true
						getDirections(rootState.mapsServiceProvider, coordinates, addReverseDirections)
							.then((response) => {
								response = (response || [])[0]
								if (response && Array.isArray(response.legs) && response.legs.length > 1) {
									// Saving New Metrics to DB
									const forwardLeg = {
										distance: response.legs[0].distance,
										duration: response.legs[0].duration,
									}
									const reverseLeg = {
										distance: response.legs[1].distance,
										duration: response.legs[1].duration,
									}
									const params = {
										metricsJSON: [
											{
												fromWarehouseId: src.warehouseId,
												toWarehouseId: dest.warehouseId,
												distanceInKms: forwardLeg.distance,
												timeInMinutes: forwardLeg.duration || 5, // Setting 5 mins as default if no duration time between two nearest/side by side locations
												loadingTimeInMinutes: src.defaultLoadingTimeInMin,
												unloadingTimeInMinutes: dest.defaultUnloadingTimeInMin,
											},
											{
												fromWarehouseId: dest.warehouseId,
												toWarehouseId: src.warehouseId,
												distanceInKms: reverseLeg.distance,
												timeInMinutes: reverseLeg.duration || 5, // Setting 5 mins as default if no duration time between two nearest/side by side locations
												loadingTimeInMinutes: dest.defaultLoadingTimeInMin,
												unloadingTimeInMinutes: src.defaultUnloadingTimeInMin,
											},
										],
										tenantId: rootState.currentTenantId,
									}
									dispatch(
										"fetchApiRequest",
										{
											apiMethod: "saveWarehouseToWarehouseMetrics",
											requestParams: params,
										},
										{ root: true }
									)
										.then((apiResponse) => {
											const { err, response } = apiResponse
											if (err) {
												reject(err.errno ? err.errmsg : "An error occured while trying to add metrics for the route.")
												return
											}
											if (response) {
												resolve(response)
											}
										})
										.catch(() => {
											reject("An error occured while trying to add metrics for the route.")
										})
								} else {
									reject("Route not available between source and destination.")
								}
							})
							.catch((error) => reject(error))
					} else {
						reject("Latitude and Longitude are not available.")
					}
				} else {
					reject("Metrics not found, You do not have permissions to add location metrics!")
				}
			})
		},
	},
	getters: {
		tripIdInPanel: (state) => {
			return state.tripIdInPanel
		},
		outboundWarehouses: (state) => (warehouseId) => {
			if (state.outboundWarehouses && state.outboundWarehouses.response) {
				// todo: do some sort of sorting
				return Object.keys(state.outboundWarehouses.response)
					.map((k) => state.outboundWarehouses.response[k])
					.filter((ow) => {
						return ow.fromWarehouseId === warehouseId && ow.loadingTimeInMinutes && ow.timeInMinutes && ow.unloadingTimeInMinutes
					})
			}
			return []
		},
		vehicleContracts: (state) => {
			if (state.vehicleContracts && state.vehicleContracts.response) {
				const sortedContracts = Object.keys(state.vehicleContracts.response)
					.map((k) => state.vehicleContracts.response[k])
					.sort((a, b) => {
						if (a.mtdPercentKmsUtilised < b.mtdPercentKmsUtilised) {
							return -1
						} else if (a.mtdPercentKmsUtilised > b.mtdPercentKmsUtilised) {
							return 1
						}
						return 0
					})
				return sortedContracts
			}
			return []
		},
		vehicleTimestamps: (state, getters, rootState) => {
			if (!rootState.date) {
				return {}
			}

			const vehicleTimestamps = {}
			const startMoment = moment(rootState.date).startOf("day")
			const endMoment = moment(rootState.date)
				.endOf("day")
				.subtract(state.cellIntervalInMinutes - 1, "minutes")

			while (startMoment.isSameOrBefore(endMoment)) {
				const key = startMoment.format("HH:mm")
				vehicleTimestamps[key] = {
					key,
					show: key.split(":")[1] === "00",
				}
				startMoment.add(state.cellIntervalInMinutes, "minutes")
			}

			return vehicleTimestamps
		},
		vehicleTrips: (state, getters, rootState) => {
			if (!rootState.date) {
				return {}
			}

			const vehicleTimestamps = getters.vehicleTimestamps

			const vehicleTrips = getters.vehicleContracts.reduce((acc, contract) => {
				acc[contract.contractId.toString()] = JSON.parse(JSON.stringify(vehicleTimestamps))
				for (const [, tsObj] of Object.entries(acc[contract.contractId.toString()])) {
					tsObj.colSpan = 1
					tsObj.show = true
					tsObj.hasTrips = false
					tsObj.trips = {
						// the reason for grouping by tripId is because we might have to show round trip as a single bar.
					}
				}
				return acc
			}, {})

			// todo: assuming this will handle loading
			if (!state.vehicleContracts.status === "success") {
				return vehicleTrips
			}

			const tripLegs = state.tripLegs && state.tripLegs.response ? state.tripLegs.response : {}

			const getTripLegMoments = (tripLeg) => {
				if (!(tripLeg && tripLeg.legStatusId !== 3 && tripLeg.scheduledStartTime && tripLeg.scheduledEndTime)) {
					return {}
				}

				const tripLegStartMoment = moment(tripLeg.scheduledStartTime)
				const tripLegEndMoment = moment(tripLeg.scheduledEndTime)

				let tripLegSchedulerStartTimestamp = nearestPastMinutes(state.cellIntervalInMinutes, tripLegStartMoment)
				let tripLegSchedulerEndTimestamp = nearestPastMinutes(state.cellIntervalInMinutes, tripLegEndMoment)

				const currentMoment = moment(rootState.date)
				// if trip leg doesn't belong to current day, skip this leg
				if (!tripLegSchedulerStartTimestamp.isSame(currentMoment, "day") && !tripLegSchedulerEndTimestamp.isSame(currentMoment, "day")) {
					// this leg belongs to a completely different day
					return {}
				}

				// if they exceed current day, reset their time to start or end of current day
				if (!tripLegSchedulerStartTimestamp.isSame(currentMoment, "day")) {
					tripLegSchedulerStartTimestamp = currentMoment.clone().startOf("day")
				}

				if (!tripLegSchedulerEndTimestamp.isSame(currentMoment, "day")) {
					tripLegSchedulerEndTimestamp = currentMoment.clone().endOf("day").second(0) // .endOf("day").subtract(state.cellIntervalInMinutes -1, "minutes").second(0);
				}

				const startTs = tripLegSchedulerStartTimestamp.format("HH:mm")
				const colSpan = Math.round(tripLegSchedulerEndTimestamp.diff(tripLegSchedulerStartTimestamp, "minutes") / state.cellIntervalInMinutes)

				return {
					tripLegSchedulerStartTimestamp,
					tripLegSchedulerEndTimestamp,
					startTs,
					colSpan,
				}
			}

			for (const [, tripLeg] of Object.entries(tripLegs)) {
				if (!(tripLeg && tripLeg.legStatusId !== 3 && tripLeg.contractId && tripLeg.scheduledStartTime && tripLeg.scheduledEndTime)) {
					continue
				}

				const { tripLegSchedulerStartTimestamp, tripLegSchedulerEndTimestamp, startTs, colSpan } = getTripLegMoments(tripLeg)
				if (!(tripLegSchedulerStartTimestamp && tripLegSchedulerEndTimestamp && startTs && colSpan)) {
					continue
				}

				// vehicles with already assigned trips for selected timeperiod will not be available
				if (!(vehicleTrips[tripLeg.contractId.toString()] && vehicleTrips[tripLeg.contractId.toString()][startTs])) {
					continue
				}

				//  if (tripLeg.tripId > 0) {
				vehicleTrips[tripLeg.contractId.toString()][startTs].trips[tripLeg.tripId.toString()] = {
					tripId: tripLeg.tripId,
					tripLegIds: [tripLeg.tripLegId],
				}
				//  }

				vehicleTrips[tripLeg.contractId.toString()][startTs].colSpan = colSpan
				vehicleTrips[tripLeg.contractId.toString()][startTs].hasTrips = true

				// set other timestamps to not show
				for (let i = 1; i < colSpan; i++) {
					const colTs = tripLegSchedulerStartTimestamp
						.clone()
						.add(state.cellIntervalInMinutes * i, "minutes")
						.format("HH:mm")
					if (!vehicleTrips[tripLeg.contractId.toString()][colTs]) {
						console.log("Could not find startTs to skip", tripLeg.contractId.toString(), startTs)
						continue
					}
					vehicleTrips[tripLeg.contractId.toString()][colTs].show = false
				}
			}

			// if tripIdInPanel then highlight clickable cells
			if (state.tripIdInPanel) {
				const tripObj = getters.getTrip(state.tripIdInPanel)
				if (tripObj && tripObj.response) {
					const trip = tripObj.response
					const tripLegs = trip.tripLegs.map(getters.getTripLeg)

					for (const tripLeg of tripLegs) {
						if (!(tripLeg && tripLeg.legStatusId !== 3 && tripLeg.scheduledStartTime && tripLeg.scheduledEndTime)) {
							continue
						}

						const { tripLegSchedulerStartTimestamp, tripLegSchedulerEndTimestamp, startTs, colSpan } = getTripLegMoments(tripLeg)
						if (!(tripLegSchedulerStartTimestamp && tripLegSchedulerEndTimestamp && startTs && colSpan)) {
							continue
						}

						// set all vehicles to show clickable button and highlight if selected
						for (const [contractId, contractTsObj] of Object.entries(vehicleTrips)) {
							if (!contractTsObj[startTs]) {
								continue
							}

							if (trip.activeContractId && trip.activeContractId.toString() === contractId) {
								contractTsObj[startTs].trips[tripLeg.tripId.toString()] = {
									tripId: tripLeg.tripId,
									tripLegIds: [tripLeg.tripLegId],
								}
							}

							contractTsObj[startTs].show = true
							contractTsObj[startTs].selectable = true
							contractTsObj[startTs].colSpan = colSpan

							// set other timestamps to not show
							for (let i = 1; i < colSpan; i++) {
								const colTs = tripLegSchedulerStartTimestamp
									.clone()
									.add(state.cellIntervalInMinutes * i, "minutes")
									.format("HH:mm")
								if (!contractTsObj[colTs]) {
									console.log("Could not find colTs to skip", colTs)
									continue
								}
								contractTsObj[colTs].show = false
							}
						}
					}
				}
			}
			return vehicleTrips
		},
		getTrip: (state) => (tripId) => {
			if (tripId && state.trips && state.trips[tripId.toString()]) {
				return state.trips[tripId.toString()]
			}
			return null
		},
		getTripLeg: (state) => (tripLegId) => {
			if (tripLegId && state.tripLegs && state.tripLegs.response && state.tripLegs.response[tripLegId.toString()]) {
				return state.tripLegs.response[tripLegId.toString()]
			}
			return null
		},
	},
}
