import Vue from "vue"
import App from "./App.vue"
import router from "./router/router"
import store from "./store/store"
import "../registerServiceWorker"
import components from "./components/components"
import "./styles/styles.scss"
import Toasted from "vue-toasted"
import firebaseApp from "../firebase"
import { getAnalytics } from "firebase/analytics"
import { getMessaging } from "firebase/messaging"
import moment from "moment"
// import { io } from "socket.io-client"
import { i18n } from "./i18n/i18n"
import { Loader } from "@googlemaps/js-api-loader"
import { formatMaterialCode } from "./Helpers/utils"

// Import VueViewer globally
import VueViewer from "v-viewer"
// import "viewerjs/dist/viewer.css";
import { VueHammer } from 'vue2-hammer'
Vue.use(VueViewer)
Vue.use(VueHammer)
import PortalVue from "portal-vue"	// import portalVue globally
Vue.use(PortalVue)

Vue.prototype.$googleMapsLoader = new Loader({
	apiKey: process.env.VUE_APP_GOOGLEMAPSTOKEN,
	version: "weekly",
	libraries: ["places", "geometry", "core"],
})

Vue.prototype.$analytics = getAnalytics(firebaseApp)
if (process.env.NODE_ENV === "production") {
	Vue.prototype.$messaging = getMessaging(firebaseApp)
}

Vue.use(Toasted, {
	position: "bottom-right",
	duration: 5000,
	keepOnHover: true,
})

Vue.config.productionTip = false

window.EventBus = new Vue()

// let socket = io(window.location.origin, {
// 	transports: ["polling", "websocket"],
// 	autoConnect: false,
// })
// socket.connect()
// Vue.prototype.$socket = socket
//
// Vue.prototype.$socket.on("connect_error", () => {
// 	// revert to classic upgrade
// 	socket.io.opts.transports = ["websocket", "polling"]
// })
//
// Vue.prototype.$socket.on("connect", () => {
// 	console.log(socket.connected) // true
// })

Vue.filter("formatTime", function (value) {
	if (!value) return ""
	return moment(value).format("h:mm A")
})
Vue.filter("formatDateShort", function (value) {
	if (!value) return ""
	value = moment(value)
	return value.format(value.year() === moment().year() ? "DD-MMM" : "DD-MMM-YY")
})
Vue.filter("formatDate", function (value) {
	if (!value) return ""
	return moment(value).format("DD MMM, YYYY")
})
Vue.filter("formatDateRaw", function (value) {
	if (!value) return ""

	return moment(String(value)).format("YYYY-MM-DD")
})
Vue.filter("formatExcelDate", function (value) {
	if (!value) return ""
	return moment(value).format("DD-MM-YYYY")
})
Vue.filter("formatDateTime", function (value) {
	if (!value) return ""
	return moment(value).format("DD MMM, YY, HH:mm")
})
Vue.filter("formatDateTimeNew", function (value) {
	if (!value) return ""
	return moment(value).format("DD MMM, YYYY, HH:mm")
})
Vue.filter("formatDateTimeSeconds", function (value) {
	if (!value) return ""
	return moment(value).format("DD MMM, YY, h:mm:ss A")
})
Vue.filter("formatDateDay", function (value) {
	if (!value) return ""
	return moment(value).format("MMM Do, YYYY")
})
Vue.filter("formatDateTimeDay", function (value) {
	if (!value) return ""
	return moment(value).format("ddd, MMM Do, YYYY, h:mm a")
})
Vue.filter("getAvator", function (value) {
	// Returns Avator for User Names
	value = value?.trim().split(" ")
	return (value?.length === 1 ? value[0].charAt(0) : value?.length >= 2 ? value[0].charAt(0) + value[value.length - 1].charAt(0) : "").toUpperCase()
})
Vue.filter("replaceNullWith", function (value, text) {
	return value !== null ? value : text
})
Vue.filter("currency", function (number, withSymbol) {
	return new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(number).replace("₹", withSymbol ? "₹ " : "")
})
Vue.filter("lowerCase", function (value) {
	return typeof value === "string" ? value.toLowerCase() : ""
})
Vue.filter("prettyBytes", function (bytes, decimals, kib, maxunit) {
	kib = kib || false
	if (bytes === 0) return "0 Bytes"
	if (isNaN(parseFloat(bytes)) && !isFinite(bytes)) return ""
	const k = kib ? 1024 : 1000
	const dm = decimals != null && !isNaN(decimals) && decimals >= 0 ? decimals : 2
	const sizes = kib ? ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB", "BiB"] : ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB", "BB"]
	var i = Math.floor(Math.log(bytes) / Math.log(k))
	if (maxunit != undefined) {
		const index = sizes.indexOf(maxunit)
		if (index != -1) i = index
	}
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
})
Vue.filter("formatMaterialCode", formatMaterialCode)

let tenantId = localStorage.getItem("defaulttenant") // check for null undefined

const getProfile = async() => await store.dispatch("getUserProfile", { tenantId })
getProfile()

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app")
