import Vue from "vue"
import moment from "moment"
import indexedDB from "./../../Helpers/indexedDBHelpers/indexedDBApiRequest"

function nearestMinutes(interval, someMoment) {
	const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval
	return someMoment.clone().minute(roundedMinutes).second(0)
}

function nearestPastMinutes(interval, someMoment) {
	const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval
	return someMoment.clone().minute(roundedMinutes).second(0)
}

function nearestFutureMinutes(interval, someMoment) {
	const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval
	return someMoment.clone().minute(roundedMinutes).second(0)
}

function formatDateTimetoDate(date) {
	return moment(date).format("YYYY-MM-DD")
}

let _negativeId = 0
const getNewId = () => --_negativeId

const convertArrayToObject = (array, key) => {
	if (!(Array.isArray(array) && array.length > 0)) {
		return {}
	}

	return array.reduce((acc, item) => {
		acc[item[key]] = item
		return acc
	}, {})
}

const setTripContractFromVehicleContract = (trip, vehicleContract, tripLegs) => {
	if (!(trip && vehicleContract)) {
		return null
	}

	const vehicleInfo = {
		activeContractId: vehicleContract.contractId,
		vehicleId: vehicleContract.vehicleId,
		vehicleNumber: vehicleContract.vehicleNumber,
		vehicleDisplayNumber: vehicleContract.vehicleDisplayNumber,
		driverId: vehicleContract.driverId,
		driverFirstName: vehicleContract.driverFirstName,
		driverLastName: vehicleContract.driverLastName,
		driverPhoneNumber: vehicleContract.driverPhoneNumber,
	}

	if (Array.isArray(tripLegs) && tripLegs.length > 0) {
		tripLegs.forEach((tl) => {
			tl.contractId = vehicleContract.contractId
		})
	}

	return Object.assign(trip, vehicleInfo)
}

/**
 * calling with `tripLegStartTimeStamp` will create a leg with `tripLegStartTimeStamp` as the loadingTime
 * and calling with `dispatchTimeStamp` will create a leg with `dispatchTimeStamp` as the dispatchTime.
 * e.g.: when you have a slot call with `dispatchTimeStamp` and when you have a previous leg call with
 * `tripLegStartTimeStamp` as the previous leg's scheduledEndTime
 * @param {Object} tripLegParams
 * @param {Number} tripLegParams.tripId
 * @param {string} tripLegParams.tripLegStartTimeStamp TIMESTAMP
 * @param {string} tripLegParams.dispatchTimeStamp TIMESTAMP
 * @param {outboundWarehouse} tripLegParams.outboundWarehouse object
 */
const createTripLeg = ({ tripId, tripLegStartTimeStamp, dispatchTimeStamp, outboundWarehouse, isReverseTripLeg }) => {
	if (!(tripId && (tripLegStartTimeStamp || dispatchTimeStamp) && outboundWarehouse)) {
		return null
	}

	// startTime = scheduledDispatchTime - loadingTime
	// endTime   = scheduledArrivalTime  + unloadingTime

	let scheduledStartMoment = tripLegStartTimeStamp ? moment(tripLegStartTimeStamp) : null
	let scheduledDispatchMoment = dispatchTimeStamp ? moment(dispatchTimeStamp) : null

	if (tripLegStartTimeStamp) {
		dispatchTimeStamp = null
		scheduledDispatchMoment = scheduledStartMoment.clone().add({ minutes: outboundWarehouse.loadingTimeInMinutes || 0 })
	} else {
		tripLegStartTimeStamp = null
		scheduledStartMoment = scheduledDispatchMoment.clone().add({ minutes: -1 * (outboundWarehouse.loadingTimeInMinutes || 0) })
	}

	const arrivalTimeInMinutes = outboundWarehouse.timeInMinutes
	const scheduledArrivalMoment = moment(scheduledDispatchMoment.format()).add({
		minutes: arrivalTimeInMinutes,
	})

	const scheduledEndMoment = scheduledArrivalMoment.clone().add({ minutes: outboundWarehouse.unloadingTimeInMinutes || 0 })

	const newTripLegObj = {
		tripLegId: getNewId(),
		tripId: tripId,
		outboundId: outboundWarehouse.outboundId,
		contractVehicleDriverId: null,
		legStatusId: 0,
		stockTypeIds: [],
		stockSubCategoryIds: [],
		scheduledDispatchTime: scheduledDispatchMoment.format(),
		scheduledArrivalTime: scheduledArrivalMoment.format(),
		scheduledStartTime: scheduledStartMoment.format(),
		scheduledEndTime: scheduledEndMoment.format(),
		inverseOutboundId: outboundWarehouse.inverseOutboundId,
		fromWarehouseId: outboundWarehouse.fromWarehouseId,
		fromWarehouseName: outboundWarehouse.fromWarehouseName,
		toWarehouseId: outboundWarehouse.toWarehouseId,
		toWarehouseName: outboundWarehouse.toWarehouseName,
		isReverseTripLeg: isReverseTripLeg,
	}

	return newTripLegObj
}

export default {
	state: {
		schedulerType: "outbound",
		vehicleContracts: null,
		slots: null,
		outboundWarehouses: null,
		hasOutboundByWarehouseId: {},
		trips: null,
		tripLegs: null,
		tripTypes: null,
		stockTypes: null,
		tripIdInPanel: null,
		cellIntervalInMinutes: 15,
		cellPxSize: 45,
		tripSubmissionState: null,
	},
	mutations: {
		setSchedulerType(state, payload) {
			state.schedulerType = payload.schedulerType
			state.vehicleContracts = null
			state.slots = null
			state.outboundWarehouses = null
			state.trips = {}
			state.tripLegs = null
		},
		setSchedulerData(state, payload) {
			if (payload.slots) {
				state.slots = {
					err: null,
					response: convertArrayToObject(payload.slots, "slotId"),
					status: "success",
				}
			}
			if (payload.outboundWarehouses) {
				state.outboundWarehouses = {
					err: null,
					response: convertArrayToObject(payload.outboundWarehouses, "outboundId"),
					status: "success",
				}
			}
			// each trip should have it's own response obj
			if (payload.trips) {
				state.trips = payload.trips.reduce((acc, trip) => {
					acc[trip.tripId] = { err: null, response: trip, status: "success" }
					return acc
				}, {})
			}
			if (payload.tripLegs) {
				// state.tripLegs = { err: null, response: convertArrayToObject(payload.tripLegs, "tripLegId"), status: "success" };
				state.tripLegs = payload.tripLegs.reduce(
					(acc, tripLeg) => {
						acc.response[tripLeg.tripLegId] = tripLeg

						// also associate trip leg to trip as well
						if (state.trips && state.trips[tripLeg.tripId.toString()] && state.trips[tripLeg.tripId.toString()].response) {
							if (!state.trips[tripLeg.tripId.toString()].response.tripLegs) {
								state.trips[tripLeg.tripId.toString()].response.tripLegs = []
							}
							state.trips[tripLeg.tripId.toString()].response.tripLegs.push(tripLeg.tripLegId)
						}

						return acc
					},
					{ err: null, response: {}, status: "success" }
				)
			}
			if (payload.stockTypes) {
				state.stockTypes = payload.stockTypes
			}
			if (payload.tripLegStockTypes) {
				payload.tripLegStockTypes.forEach((tlst) => {
					if (!(tlst && tlst.tripLegId && tlst.stockTypeId)) {
						return
					}

					if (state.tripLegs && state.tripLegs.response && state.tripLegs.response[tlst.tripLegId]) {
						const tripLeg = state.tripLegs.response[tlst.tripLegId]
						if (!tripLeg.stockTypeIds) {
							Vue.set(tripLeg, "stockTypeIds", [])
						}
						tripLeg.stockTypeIds.push(tlst)
					}
				})
			}
			if (payload.tripTypes) {
				state.tripTypes = convertArrayToObject(payload.tripTypes, "tripTypeId")
			}
		},
		gettingVehicleContracts(state, payload) {
			state.vehicleContracts = { err: null, response: {}, status: "loading" }
		},
		setVehicleContracts(state, payload) {
			if (payload.vehicleContracts) {
				state.vehicleContracts = {
					err: null,
					response: convertArrayToObject(payload.vehicleContracts, "contractId"),
					status: "success",
				}
			}
		},
		setContract(state, payload) {
			if (!(state.vehicleContracts && state.vehicleContracts.response)) {
				return
			}

			Vue.set(state.vehicleContracts.response, payload.contractId, payload)
		},
		setTrip(state, payload) {
			if (!(payload && payload.response && payload.response.tripId)) {
				return
			}

			Vue.set(state.trips, payload.response.tripId, payload)
		},
		setTripLeg(state, payload) {
			if (!(state.tripLegs && state.tripLegs.response)) {
				return
			}
			if (state.stockTypes.length === 1 && payload.stockTypeIds.length === 0) {
				payload.stockTypeIds = [state.stockTypes[0]]
			}

			Vue.set(state.tripLegs.response, payload.tripLegId, payload)
		},
		removeTripLeg(state, tripLegId) {
			if (state.tripLegs && state.tripLegs.response && state.tripLegs.response[tripLegId.toString()]) {
				Vue.delete(state.tripLegs.response, tripLegId.toString())
			}
		},
		removeTrip(state, tripId) {
			if (state.trips && state.trips[tripId.toString()]) {
				const { response } = state.trips[tripId.toString()]
				if (Array.isArray(response.tripLegs)) {
					response.tripLegs.forEach((tlid) => {
						Vue.delete(state.tripLegs.response, tlid.toString())
					})
				}
				Vue.delete(state.trips, tripId.toString())
			}
		},
		setTripIdInPanel(state, tripId) {
			state.tripIdInPanel = tripId
		},
		setOutboundWarehouse(state, outboundWarehousesArray) {
			if (!Array.isArray(outboundWarehousesArray)) {
				return
			}

			if (!state.outboundWarehouses.response) {
				state.outboundWarehouses = {
					err: null,
					response: outboundWarehousesArray,
					status: "success",
				}
			} else {
				for (let index = 0; index < outboundWarehousesArray.length; index++) {
					const ow = outboundWarehousesArray[index]
					Vue.set(state.outboundWarehouses.response, ow.outboundId, ow)
				}
			}
		},
		setHasOutboundByWarehouseId(state, warehouseId) {
			Vue.set(state.hasOutboundByWarehouseId, warehouseId, true)
		},
	},
	actions: {
		async getSchedulerData({ state, commit, dispatch, rootState }, { warehouseId, date }) {
			const apiMethod = state.schedulerType === "outbound" ? "getSchedulerOutbound" : "getSchedulerInbound"
			//obsereved that only getSchedulerOutbound api end points are defined.
			//outbound and getSchedulerInbound points are not defined in existing application
			let tenantId = rootState.currentTenantId
			if (apiMethod === "getSchedulerOutbound") {
				let requestParams = { warehouseId, date, tenantId }

				let data = await indexedDB.indexedDBFetchApi("getSchedulerOutboundDelta", requestParams, rootState.user.response.userId)
				const schedulerResponse = data && data.response ? data.response : {}

				schedulerResponse.trips = schedulerResponse.trips.filter((trip) => {
					return trip.tripStatusId != 3
				})
				schedulerResponse.tripLegs = schedulerResponse.tripLegs.filter((tripLeg) => {
					return tripLeg.legStatusId != 3
				})

				commit("setSchedulerData", schedulerResponse)
				commit("setCurrentWarehouse", schedulerResponse.contextWarehouse)
				commit("setHasOutboundByWarehouseId", schedulerResponse.contextWarehouse.warehouseId)

				// also refresh vehicles
				dispatch("getSchedulerVehicleContracts", { warehouseId, date, tenantId })

				// remove panel when navigating back
				if (state.tripIdInPanel) {
					commit("setTripIdInPanel", null)
				}
				return
			}
			// written else part to handle the outbound and getSchedulerInbound requests if any with out using delta.
			else {
				const data = await dispatch("fetchApiRequest", {
					apiMethod,
					requestParams: { warehouseId, date, tenantId },
				})

				const schedulerResponse = data && data.response ? data.response : {}

				commit("setSchedulerData", schedulerResponse)
				commit("setCurrentWarehouse", schedulerResponse.contextWarehouse)
				commit("setHasOutboundByWarehouseId", schedulerResponse.contextWarehouse.warehouseId)

				// also refresh vehicles
				dispatch("getSchedulerVehicleContracts", { warehouseId, date, tenantId })

				// remove panel when navigating back
				if (state.tripIdInPanel) {
					commit("setTripIdInPanel", null)
				}
				return
			}
		},
		async getSchedulerVehicleContracts({ state, commit, dispatch, rootState }, { warehouseId, date, limit, offset, datesArray, alsoIncludeContractId }) {
			// todo: check if datesArray has valid fields (startDate, endDate)

			commit("gettingVehicleContracts")
			let tenantId = rootState.currentTenantId

			let apiMethod = "getSchedulerVehicleContractsDelta"
			let requestParams = {
						warehouseId,
						date,
						limit,
						offset,
						datesArray,
						alsoIncludeContractId,
						tenantId,
			}
			const data = await indexedDB.indexedDBFetchApi(apiMethod, requestParams, rootState.user.response.userId)
			let vehicleContractsResponse = data && data.response ? data.response : {}
			if (Array.isArray(datesArray) && datesArray.length > 0) {
				const request = await dispatch("fetchApiRequest", {
					apiMethod: "getBusyContracts",
					requestParams: {
					warehouseId,
					date,
					limit,
					offset,
					datesArray,
					alsoIncludeContractId,
					tenantId,
					},
				})
				const busyContracts = request && request.response ? request.response.busyContracts : {}
				vehicleContractsResponse.vehicleContracts = vehicleContractsResponse.vehicleContracts.filter(vc => !busyContracts.includes(vc.contractId))
				}
				commit("setVehicleContracts", vehicleContractsResponse)
				return
		},
		async getOutboundWarehouseByWarehouseId({ state, commit, dispatch }, { warehouseId, tenantId }) {
			if (state.hasOutboundByWarehouseId[warehouseId]) {
				return
			}

			commit("setHasOutboundByWarehouseId", warehouseId)
			// let tenantId = rootState.currentTenantId;
			const apiResponse = await dispatch("fetchApiRequest", {
				apiMethod: "getOutboundWarehouseByWarehouseId",
				requestParams: { warehouseId, tenantId },
			})

			const ows = apiResponse.response ? apiResponse.response : []

			commit("setOutboundWarehouse", ows)

			return
		},
		tryAndCreateNewTrip({ state, commit, dispatch, rootState }, { slotObj, outboundWarehouse, vehicleContract }) {
			if (!(slotObj && outboundWarehouse && outboundWarehouse.timeInMinutes)) {
				return
			}

			const newTripObj = {
				tripId: getNewId(),
				scheduledInWarehouseId: rootState.currentWarehouseId,
				scheduledForDate: rootState.date + "T" + slotObj.slotStartTime + rootState.timezone,
				// scheduledForDate: rootState.date,
				tripTypeId: 1,
				tripStatusId: 0,
				tripLegs: [],
			}

			// Set vehicle if available
			if (vehicleContract) {
				setTripContractFromVehicleContract(newTripObj, vehicleContract)
			}

			// One Way trip
			if (newTripObj.tripTypeId === 1) {
				const newTripLegObj = createTripLeg({
					tripId: newTripObj.tripId,
					tripLegStartTimeStamp: null,
					dispatchTimeStamp: newTripObj.scheduledForDate,
					outboundWarehouse,
					isReverseTripLeg: 0,
				})

				newTripObj.tripLegs.push(newTripLegObj.tripLegId)

				commit("setTripLeg", newTripLegObj)
			}

			commit("setTrip", { err: null, status: "success", response: newTripObj })

			// default trip should be round trip
			dispatch("changeTripType", { trip: newTripObj, tripType: { tripTypeId: 2 } })
			if (rootState.tenantConfigurationJSON.Trip && rootState.tenantConfigurationJSON.Trip.defaultTripTypeId === 3) {
				dispatch("changeTripType", { trip: newTripObj, tripType: { tripTypeId: 3 } })
			}
			commit("setTripIdInPanel", newTripObj.tripId)
		},
		setVehicleContractToTrip({ state, commit, dispatch, rootState }, { tripId, vehicleContract }) {
			const tripState = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()])) : null
			if (!tripState) {
				return
			}
			const trip = tripState.response

			const tripLegs = Array.isArray(trip.tripLegs) ? trip.tripLegs.map((legId) => state.tripLegs.response[legId.toString()]).filter((x) => (x ? true : false)) : []

			setTripContractFromVehicleContract(trip, vehicleContract, tripLegs) //

			commit("setTrip", tripState)
			tripLegs.map((tl) => commit("setTripLeg", tl))
		},
		changeTripType({ state, commit, dispatch, rootState }, { trip, tripType }) {
			// only change trip type if it's an ephermal trip
			if (!(trip && tripType && trip.tripId <= 0)) {
				return
			}

			trip = JSON.parse(JSON.stringify(trip))

			trip.tripTypeId = tripType.tripTypeId

			if (tripType.tripTypeId === 1 || tripType.tripTypeId === 2 || tripType.tripTypeId === 3) {
				// remove all legs except the first leg
				trip.tripLegs.splice(1, trip.tripLegs.length).forEach((tripLegId) => commit("removeTripLeg", tripLegId))
			}

			if (tripType.tripTypeId === 2 || tripType.tripTypeId === 3) {
				// add new trip leg from destination to source
				const sourceToDestTripLeg = state.tripLegs.response[trip.tripLegs[0].toString()]
				const sourceToDestOutbound = state.outboundWarehouses.response[sourceToDestTripLeg.outboundId.toString()]
				const destToSourceOutbound = state.outboundWarehouses.response[sourceToDestOutbound.inverseOutboundId.toString()]

				const destToSourceTripLeg = createTripLeg({
					tripId: trip.tripId,
					tripLegStartTimeStamp: sourceToDestTripLeg.scheduledEndTime,
					dispatchTimeStamp: null,
					outboundWarehouse: destToSourceOutbound,
					isReverseTripLeg: 0,
				})

				trip.tripLegs.push(destToSourceTripLeg.tripLegId)

				commit("setTripLeg", destToSourceTripLeg)
			}
			commit("setTrip", { err: null, status: "success", response: trip })
		},
		addTripLeg({ state, commit, dispatch, rootState }, { tripId, outboundWarehouse, isReverseTripLeg }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip) {
				// console.log("Could not find trip to add new leg");
				return
			}

			if (!(outboundWarehouse && outboundWarehouse.timeInMinutes > 0)) {
				console.log("Missing timeInMinutes for outbound warehouse")
				return
			}

			if (trip.tripLegs.length > 0) {
				// get last leg
				const lastLeg = state.tripLegs.response[trip.tripLegs[trip.tripLegs.length - 1].toString()]

				if (lastLeg.toWarehouseId !== outboundWarehouse.fromWarehouseId) {
					console.log("Last leg doesn't end where this leg wants to start")
					return
				}

				const newTripLeg = createTripLeg({
					tripId: trip.tripId,
					tripLegStartTimeStamp: lastLeg.scheduledEndTime,
					dispatchTimeStamp: null,
					outboundWarehouse,
					isReverseTripLeg,
				})

				trip.tripLegs.push(newTripLeg.tripLegId)

				commit("setTripLeg", newTripLeg)

				commit("setTrip", { err: null, status: "success", response: trip })
			} else {
				// this will be the first leg
				const newTripLeg = createTripLeg({
					tripId: trip.tripId,
					tripLegStartTimeStamp: null,
					dispatchTimeStamp: trip.scheduledForDate,
					outboundWarehouse,
					isReverseTripLeg,
				})

				trip.tripLegs.push(newTripLeg.tripLegId)
				commit("setTripLeg", newTripLeg)

				// if this is the first leg and the trip is of type round trip then add the return trip leg as well
				if (trip.tripTypeId === 2) {
					const inverseOutboundWarehouse = state.outboundWarehouses.response[outboundWarehouse.inverseOutboundId.toString()]
					const returnTripLeg = createTripLeg({
						tripId: trip.tripId,
						tripLegStartTimeStamp: newTripLeg.scheduledEndTime,
						dispatchTimeStamp: null,
						outboundWarehouse: inverseOutboundWarehouse,
						isReverseTripLeg,
					})

					trip.tripLegs.push(returnTripLeg.tripLegId)
					commit("setTripLeg", returnTripLeg)
				}

				commit("setTrip", { err: null, status: "success", response: trip })
			}
		},
		changeTripLegLocation({ state, commit, dispatch, rootState }, { tripId, tripLegId, outboundWarehouse, isReverseTripLeg }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip) {
				console.log("Could not find trip to add new leg")
				return
			}

			if (!(outboundWarehouse && outboundWarehouse.timeInMinutes > 0)) {
				console.log("Missing timeInMinutes for outbound warehouse")
				return
			}

			if (tripId > 0) {
				// todo: what should happen to already created trip
				return
			}

			trip.tripLegs.splice(trip.tripLegs.indexOf(tripLegId), trip.tripLegs.length).forEach((tripLegId) => commit("removeTripLeg", tripLegId))

			commit("setTrip", { err: null, status: "success", response: trip })

			dispatch("addTripLeg", { tripId, outboundWarehouse, isReverseTripLeg })
		},
		removeTripLeg({ state, commit, dispatch, rootState }, { tripId, tripLegId }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip) {
				console.log("Could not find trip to remove leg")
				return
			}

			if (tripId > 0) {
				// todo: what should happen to already created trip
				return
			}

			if ((trip.tripTypeId === 1 || trip.tripTypeId === 3) && trip.tripLegs.indexOf(tripLegId) === 0) {
				// don't remove first leg
				return
			} else if (trip.tripTypeId === 2 && trip.tripLegs.indexOf(tripLegId) <= 1) {
				// don't remove second leg if it's a round trip
				return
			}

			trip.tripLegs.splice(trip.tripLegs.indexOf(tripLegId), trip.tripLegs.length).forEach((tripLegId) => commit("removeTripLeg", tripLegId))

			commit("setTrip", { err: null, status: "success", response: trip })
		},
		changeTripLegDepartureDate({ state, commit, dispatch, rootState }, { tripId, tripLegId, newDate }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip) {
				console.log("Could not find trip to change departure date")
				return
			}

			if (!(tripLegId && state.tripLegs.response[tripLegId.toString()])) {
				console.log("Could not find trip leg to change departure date")
				return
			}

			const modifiedTripLeg = state.tripLegs.response[tripLegId.toString()]
			const modifiedTripLegIndex = trip.tripLegs.indexOf(tripLegId)

			// Trip leg departure date cannot be less than previous leg's end time
			// unless this is the first leg.
			const minumumAllowedTimeStampMoment =
				modifiedTripLegIndex === 0
					? null
					: (() => {
							// get previous trip leg
							const prevTripLegId = trip.tripLegs[modifiedTripLegIndex - 1]
							const prevTripLeg = state.tripLegs.response[prevTripLegId.toString()]

							const modifiedTripLegOutbound = state.outboundWarehouses.response[modifiedTripLeg.outboundId.toString()]

							return moment(prevTripLeg.scheduledEndTime).add({
								minutes: modifiedTripLegOutbound.loadingTimeInMinutes || 0,
							})
					  })()

			if (modifiedTripLegIndex > 0) {
				if (moment(newDate).isBefore(minumumAllowedTimeStampMoment)) {
					console.log("Trip leg departure date cannot be less than previous leg's end time")
					return
				}
			} else if (modifiedTripLegIndex === 0 && tripId < 0) {
				// if this is the first leg of new trip, modifiy new time of trip as well
				trip.scheduledForDate = newDate
				commit("setTrip", { err: null, response: trip, status: "success" })
			}

			const differenceInMinutes = moment(newDate).diff(modifiedTripLeg.scheduledDispatchTime, "minutes")

			// add this differenceInMinutes to all trip legs right from the changed trip leg
			for (let index = modifiedTripLegIndex; index < trip.tripLegs.length; index++) {
				const currentTripLegId = trip.tripLegs[index]
				const currentTripLeg = JSON.parse(JSON.stringify(state.tripLegs.response[currentTripLegId.toString()]))

				currentTripLeg.scheduledDispatchTime = moment(currentTripLeg.scheduledDispatchTime).add({ minutes: differenceInMinutes }).format()
				currentTripLeg.scheduledArrivalTime = moment(currentTripLeg.scheduledArrivalTime).add({ minutes: differenceInMinutes }).format()
				currentTripLeg.scheduledStartTime = moment(currentTripLeg.scheduledStartTime).add({ minutes: differenceInMinutes }).format()
				currentTripLeg.scheduledEndTime = moment(currentTripLeg.scheduledEndTime).add({ minutes: differenceInMinutes }).format()

				commit("setTripLeg", currentTripLeg)
			}

			if (tripId > 0) {
				// dispatch("fetchApiRequest", {
				//     apiMethod: "slideTripLegDepartureTime",
				//     requestParams: { tripId, tripLegId, modifiedScheduledStartTime: state.tripLegs.response[modifiedTripLeg.tripLegId.toString()].scheduledStartTime }
				// }).then(({ err, response, status }) => {
				//     if (err) {
				//         Vue.toasted.show(err && err.errmsg ? err.errmsg : err);
				//         console.log("Could not change departure time", err);
				//         return;
				//     }
				//     dispatch("setTripDetail", { err, response, status });
				// });
			}
		},
		async createTrip({ state, commit, dispatch, rootState }, { tripId, persistAfterSubmit }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null
			if (!trip) {
				console.log("Could not find trip in store to create new trip")
				return
			}

			if (tripId > 0) {
				// todo: what should happen to already created trip
				return
			}

			trip.tripLegs = trip.tripLegs.map((legId) => state.tripLegs.response[legId.toString()])

			const tripRequest = {
				tripId: trip.tripId,
				tripTypeId: trip.tripTypeId,
				scheduledInWarehouseId: trip.scheduledInWarehouseId,
				scheduledForDate: formatDateTimetoDate(trip.scheduledForDate),
				contractId: trip.activeContractId,
				tripLegs: trip.tripLegs.map((tl) => {
					return {
						tripLegId: tl.tripLegId,
						tripId: tl.tripId,
						outboundId: tl.outboundId,
						scheduledStartTime: tl.scheduledStartTime,
						scheduledDispatchTime: tl.scheduledDispatchTime,
						scheduledArrivalTime: tl.scheduledArrivalTime,
						scheduledEndTime: tl.scheduledEndTime,
						stockTypeIds: (tl.stockTypeIds || []).map((sc) => sc.stockTypeId).filter((id) => (id ? true : false)),
						stockSubCategoryIds: (tl.stockSubCategoryIds || []).map((sc) => sc.stockSubCategoryId).filter((id) => (id ? true : false)),
						isReverseTripLeg: tl.isReverseTripLeg,
					}
				}),
			}

			if (!Array.isArray(tripRequest.tripLegs) || tripRequest.tripLegs.find((leg) => ((leg || { stockTypeIds: [] }).stockTypeIds || []).length === 0)) {
				Vue.toasted.error("Missing stock type.")
				return
			}

			rootState.tripStore.tripSubmissionState = "inprogress"
			tripRequest.tenantId = rootState.currentTenantId
			const { err, response, status } = await dispatch("fetchApiRequest", {
				apiMethod: "createTrip",
				requestParams: tripRequest,
			})

			rootState.tripStore.tripSubmissionState = null

			if (err) {
				Vue.toasted.error(err.errno ? err.errmsg : "An error occured while trying to save trip")
				console.log("Could not create trip", err)
				return
			}

			// delete ephemeral trip and it's legs
			trip.tripLegs.map((tl) => commit("removeTripLeg", tl.tripLegId))
			commit("removeTrip", trip.tripId)

			dispatch("setTripDetail", { err, response, status })

			commit("setTripIdInPanel", response.tripId)

			//finally close popup
			if (!persistAfterSubmit) {
				dispatch("cancelTripPanel", { tripId: response.tripId })
				Vue.toasted.success("Trip details have been saved successfully.")
			}

			return response
		},
		async editTrip({ state, commit, dispatch, rootState }, { tripId, persistAfterSubmit }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null

			if (!trip) {
				console.log("Could not find trip in store to create new trip")
				return
			}

			if (tripId < 0) {
				// todo: what should happen to already created trip
				return
			}

			trip.tripLegs = trip.tripLegs.map((legId) => state.tripLegs.response[legId.toString()])

			const tripRequest = {
				tripId: trip.tripId,
				tripTypeId: trip.tripTypeId,
				scheduledInWarehouseId: trip.scheduledInWarehouseId,
				scheduledForDate: trip.scheduledForDate,
				contractId: trip.activeContractId,
				tripLegs: trip.tripLegs.map((tl) => {
					return {
						tripLegId: tl.tripLegId,
						tripId: tl.tripId,
						outboundId: tl.outboundId,
						scheduledStartTime: tl.scheduledStartTime,
						scheduledDispatchTime: tl.scheduledDispatchTime,
						scheduledArrivalTime: tl.scheduledArrivalTime,
						scheduledEndTime: tl.scheduledEndTime,
						stockTypeIds: (tl.stockTypeIds || []).map((sc) => sc.stockTypeId).filter((id) => (id ? true : false)),
						stockSubCategoryIds: (tl.stockSubCategoryIds || []).map((sc) => sc.stockSubCategoryId).filter((id) => (id ? true : false)),
					}
				}),
			}

			if (!Array.isArray(tripRequest.tripLegs) || tripRequest.tripLegs.find((leg) => ((leg || { stockTypeIds: [] }).stockTypeIds || []).length === 0)) {
				Vue.toasted.error("Missing stock type.")
				return
			}

			rootState.tripStore.tripSubmissionState = "inprogress"

			tripRequest.tenantId = rootState.currentTenantId
			const { err, response, status } = await dispatch("fetchApiRequest", {
				apiMethod: "editTrip",
				requestParams: tripRequest,
			})

			rootState.tripStore.tripSubmissionState = null

			if (err) {
				Vue.toasted.error(err.errno ? err.errmsg : "An error occured while trying to edit trip")
				console.log("Could not edit trip", err)
				return
			}
			//  console.log(response.tripId);
			trip.tripLegs.map((tl) => commit("removeTripLeg", tl.tripLegId))
			commit("removeTrip", trip.tripId)

			dispatch("setTripDetail", { response, status })

			commit("setTripIdInPanel", response.tripId)

			//finally close popup
			if (!persistAfterSubmit) {
				dispatch("cancelTripPanel", { tripId: trip.tripId })
				Vue.toasted.success("Trip details have been updated successfully.")
			}

			return response
		},

		async cancelTrip({ state, commit, dispatch, rootState }, { tripId, tripCancelReasonId, description }) {
			const trip = state.trips[tripId.toString()] && state.trips[tripId.toString()].response ? JSON.parse(JSON.stringify(state.trips[tripId.toString()].response)) : null

			if (!trip || tripId < 0) {
				Vue.toasted.error(err.errno ? err.errmsg : "Could not find trip in store to cancel the trip")
				return
			}

			rootState.tripStore.tripSubmissionState = "inprogress"

			const { err, response, status } = await dispatch("fetchApiRequest", {
				apiMethod: "cancelTrip",
				requestParams: {
					tripId: tripId,
					tripCancelReasonId: tripCancelReasonId,
					description: description,
					tenantId: rootState.currentTenantId,
				},
			})

			rootState.tripStore.tripSubmissionState = null

			if (err) {
				Vue.toasted.error(err.errno ? err.errmsg : "An error occured while trying to cancel trip")
				console.log("Could not cancel trip", err)
				return
			} else {
				Vue.toasted.success("Trip details have been cancelled successfully.")
			}

			commit("removeTrip", trip.tripId)

			commit("setTripIdInPanel", null)

			//finally close popup
			dispatch("cancelTripPanel", { tripId: trip.tripId })

			return
		},
		setTripDetail({ state, commit, dispatch, rootState }, { err, response, status }) {
			response.tripLegs = response.tripLegs.map((tl) => {
				tl.stockTypeIds = []
				;(response.tripLegStockTypes || [])
					.filter((tlst) => {
						return tlst.tripLegId === tl.tripLegId
					})
					.forEach((tsc) => {
						tl.stockTypeIds.push(tsc)
					})

				commit("setTripLeg", tl)

				return tl.tripLegId
			})

			commit("setTrip", { err, response, status: "success" })
		},

		cancelTripPanel({ state, commit, dispatch, rootState }) {
			const tripIdInPanel = state.tripIdInPanel
			if (!tripIdInPanel) {
				return
			}
			const tripResponse = state.trips[tripIdInPanel.toString()]
			if (!(tripResponse && tripResponse.response)) {
				return
			}
			const trip = tripResponse.response
			if (tripIdInPanel > 0) {
				// reset by getting from server
				dispatch("fetchApiRequest", {
					apiMethod: "v1/getTripDetail",
					requestParams: { tripId: tripIdInPanel, tenantId: rootState.currentTenantId },
				})
					.then(({ err, response, status }) => {
						if (err) {
							Vue.toasted.show(err && err.errmsg ? err.errmsg : err)
							console.log("Could not get trip", err)
							return
						}
						// delete ephemeral trip and it's legs
						commit("removeTrip", trip.tripId)

						dispatch("setTripDetail", { err, response, status })
					})
					.catch((err) => {
						this.tripDetailResponse = {
							err: "An Error occurred when getting trip",
							response: null,
							status: "error",
						}
						console.error(err)
					})
			} else {
				// delete ephemeral trip and it's legs
				commit("removeTrip", trip.tripId)
			}
			commit("setTripIdInPanel", null)

			// also refresh vehicles
			dispatch("getSchedulerVehicleContracts", {
				warehouseId: rootState.currentWarehouseId,
				date: rootState.date,
			})
		},
	},
	getters: {
		tripIdInPanel: (state, getters) => {
			return state.tripIdInPanel
		},
		selectedTripInPanel: (state, getters) => {
			if (getters.tripIdInPanel) {
				return state.trips[getters.tripIdInPanel]
			}
			return null
		},
		tripDetailInPanel: (state, getters) => {
			if (getters.selectedTripInPanel && getters.selectedTripInPanel.response) {
				return getters.selectedTripInPanel.response
			}
			return null
		},
		selectedContractIdInPanel: (state, getters) => {
			if (getters.tripDetailInPanel && getters.tripDetailInPanel.activeContractId) {
				return getters.tripDetailInPanel.activeContractId
			}
			return null
		},
		slotsByTimestamp: (state, getters) => {
			const slotsByTimestamp = {}
			if (state.slots && state.slots.response) {
				for (const [slotId, slot] of Object.entries(state.slots.response)) {
					if (slot && slot.slotStartTime) {
						slotsByTimestamp[slot.slotStartTime] = slot
					}
				}
			}
			return slotsByTimestamp
		},
		slotOutboundTripLegs: (state, getters, rootState) => {
			const slotObj = JSON.parse(JSON.stringify(getters.slotsByTimestamp))
			const tripLegs = state.tripLegs && state.tripLegs.response ? state.tripLegs.response : {}

			for (const [key, tripLeg] of Object.entries(tripLegs)) {
				if (!(tripLeg.fromWarehouseId === rootState.currentWarehouseId)) {
					continue
				}

				const scheduledDispatchMoment = moment(tripLeg.scheduledDispatchTime)
				if (scheduledDispatchMoment.isSame(rootState.date, "day")) {
					const time = scheduledDispatchMoment.format("HH:mm:ss")

					if (!slotObj.hasOwnProperty(time)) {
						slotObj[time] = {
							slotId: null,
							slotStartTime: time,
						}
					}

					if (!slotObj[time].hasOwnProperty("tripLegsByOutboundId")) {
						slotObj[time].tripLegsByOutboundId = {}
					}

					if (!slotObj[time].tripLegsByOutboundId.hasOwnProperty(tripLeg.outboundId.toString())) {
						slotObj[time].tripLegsByOutboundId[tripLeg.outboundId.toString()] = {
							outboundId: tripLeg.outboundId,
							tripLegs: [],
						}
					}

					slotObj[time].tripLegsByOutboundId[tripLeg.outboundId.toString()].tripLegs.push(tripLeg)
				}
			}

			return slotObj
		},
		orderedSlots: (state, getters) => {
			return Object.keys(getters.slotOutboundTripLegs)
				.sort()
				.map((t) => getters.slotOutboundTripLegs[t])
		},
		vehicleContracts: (state, getters) => {
			const selectedContractIdInPanel = getters.selectedContractIdInPanel
			if (state.vehicleContracts && state.vehicleContracts.response) {
				const sortedContracts = Object.keys(state.vehicleContracts.response)
					.map((k) => state.vehicleContracts.response[k])
					.sort((a, b) => {
						if (a.mtdPercentKmsUtilised < b.mtdPercentKmsUtilised) {
							return -1
						} else if (a.mtdPercentKmsUtilised > b.mtdPercentKmsUtilised) {
							return 1
						}
						return 0
					})

				// if (selectedContractIdInPanel) {
				//     const selectedContractIndex = sortedContracts.findIndex(c => c.contractId === selectedContractIdInPanel);
				//     const selectedContract = selectedContractIndex > -1 ? sortedContracts.splice(selectedContractIndex, 1) : null;
				//     if (selectedContract && selectedContract[0]) {
				//         sortedContracts.unshift(selectedContract[0]);
				//     }
				// }

				return sortedContracts
			}
			return []
		},
		outboundWarehouses: (state, getters) => (warehouseId) => {
			if (state.outboundWarehouses && state.outboundWarehouses.response) {
				// todo: do some sort of sorting
				return Object.keys(state.outboundWarehouses.response)
					.map((k) => state.outboundWarehouses.response[k])
					.filter((ow) => {
						return ow.fromWarehouseId === warehouseId && ow.loadingTimeInMinutes && ow.timeInMinutes && ow.unloadingTimeInMinutes
					})
			}
			return []
		},
		getTripLeg: (state, getters) => (tripLegId) => {
			if (tripLegId && state.tripLegs && state.tripLegs.response && state.tripLegs.response[tripLegId.toString()]) {
				return state.tripLegs.response[tripLegId.toString()]
			}
			return null
		},
		getTrip: (state, getters) => (tripId) => {
			if (tripId && state.trips && state.trips[tripId.toString()]) {
				return state.trips[tripId.toString()]
			}
			return null
		},
		// vehicle trips by time interval
		vehicleTimestamps: (state, getters, rootState) => {
			if (!rootState.date) {
				return {}
			}

			const vehicleTimestamps = {}
			const startMoment = moment(rootState.date).startOf("day")
			const endMoment = moment(rootState.date)
				.endOf("day")
				.subtract(state.cellIntervalInMinutes - 1, "minutes")

			while (startMoment.isSameOrBefore(endMoment)) {
				const key = startMoment.format("HH:mm")
				vehicleTimestamps[key] = {
					key,
					show: key.split(":")[1] === "00",
				}
				startMoment.add(state.cellIntervalInMinutes, "minutes")
			}

			return vehicleTimestamps
		},
		vehicleTrips: (state, getters, rootState) => {
			if (!rootState.date) {
				return {}
			}

			const vehicleTimestamps = getters.vehicleTimestamps

			const vehicleTrips = getters.vehicleContracts.reduce((acc, contract) => {
				acc[contract.contractId.toString()] = JSON.parse(JSON.stringify(vehicleTimestamps))
				for (const [key, tsObj] of Object.entries(acc[contract.contractId.toString()])) {
					tsObj.colSpan = 1
					tsObj.show = true
					tsObj.hasTrips = false
					tsObj.trips = {
						// the reason for grouping by tripId is because we might have to show round trip as a single bar.
					}
				}
				return acc
			}, {})

			// todo: assuming this will handle loading
			if (!state.vehicleContracts.status == "success") {
				return vehicleTrips
			}

			const tripLegs = state.tripLegs && state.tripLegs.response ? state.tripLegs.response : {}

			const getTripLegMoments = (tripLeg) => {
				if (!(tripLeg && tripLeg.tripLegStatusId !== 3 && tripLeg.scheduledStartTime && tripLeg.scheduledEndTime)) {
					return {}
				}

				const tripLegStartMoment = moment(tripLeg.scheduledStartTime)
				const tripLegEndMoment = moment(tripLeg.scheduledEndTime)

				let tripLegSchedulerStartTimestamp = nearestPastMinutes(state.cellIntervalInMinutes, tripLegStartMoment)
				let tripLegSchedulerEndTimestamp = nearestPastMinutes(state.cellIntervalInMinutes, tripLegEndMoment)

				const currentMoment = moment(rootState.date)
				// if trip leg doesn't belong to current day, skip this leg
				if (!tripLegSchedulerStartTimestamp.isSame(currentMoment, "day") && !tripLegSchedulerEndTimestamp.isSame(currentMoment, "day")) {
					// this leg belongs to a completely different day
					return {}
				}

				// if they exceed current day, reset their time to start or end of current day
				if (!tripLegSchedulerStartTimestamp.isSame(currentMoment, "day")) {
					tripLegSchedulerStartTimestamp = currentMoment.clone().startOf("day")
				}

				if (!tripLegSchedulerEndTimestamp.isSame(currentMoment, "day")) {
					tripLegSchedulerEndTimestamp = currentMoment.clone().endOf("day").second(0) // .endOf("day").subtract(state.cellIntervalInMinutes -1, "minutes").second(0);
				}

				const startTs = tripLegSchedulerStartTimestamp.format("HH:mm")
				const colSpan = Math.round(tripLegSchedulerEndTimestamp.diff(tripLegSchedulerStartTimestamp, "minutes") / state.cellIntervalInMinutes)

				return {
					tripLegSchedulerStartTimestamp,
					tripLegSchedulerEndTimestamp,
					startTs,
					colSpan,
				}
			}

			for (const [tripLegIdKey, tripLeg] of Object.entries(tripLegs)) {
				if (!(tripLeg && tripLeg.tripLegStatusId !== 3 && tripLeg.contractId && tripLeg.scheduledStartTime && tripLeg.scheduledEndTime)) {
					continue
				}

				const { tripLegSchedulerStartTimestamp, tripLegSchedulerEndTimestamp, startTs, colSpan } = getTripLegMoments(tripLeg)
				if (!(tripLegSchedulerStartTimestamp && tripLegSchedulerEndTimestamp && startTs && colSpan)) {
					continue
				}

				// vehicles with already assigned trips for selected timeperiod will not be available
				if (!(vehicleTrips[tripLeg.contractId.toString()] && vehicleTrips[tripLeg.contractId.toString()][startTs])) {
					continue
				}

				//  if (tripLeg.tripId > 0) {
				vehicleTrips[tripLeg.contractId.toString()][startTs].trips[tripLeg.tripId.toString()] = {
					tripId: tripLeg.tripId,
					tripLegIds: [tripLeg.tripLegId],
				}
				//  }

				vehicleTrips[tripLeg.contractId.toString()][startTs].colSpan = colSpan
				vehicleTrips[tripLeg.contractId.toString()][startTs].hasTrips = true

				// console.log(console.log(state.tripIdInPanel))
				// if(state.tripIdInPanel == tripLeg.tripId){
				//     vehicleTrips[tripLeg.contractId.toString()][startTs].colSpan = colSpan;
				//     vehicleTrips[tripLeg.contractId.toString()][startTs].hasTrips = false;
				//     vehicleTrips[tripLeg.contractId.toString()][startTs].trips[tripLeg.tripId.toString()] = {
				//     }
				// }

				// set other timestamps to not show
				for (let i = 1; i < colSpan; i++) {
					const colTs = tripLegSchedulerStartTimestamp
						.clone()
						.add(state.cellIntervalInMinutes * i, "minutes")
						.format("HH:mm")
					if (!vehicleTrips[tripLeg.contractId.toString()][colTs]) {
						console.log("Could not find startTs to skip", tripLeg.contractId.toString(), startTs)
						continue
					}
					vehicleTrips[tripLeg.contractId.toString()][colTs].show = false
				}
			}

			// if tripIdInPanel then highlight clickable cells
			if (state.tripIdInPanel) {
				const tripObj = getters.getTrip(state.tripIdInPanel)
				if (tripObj && tripObj.response) {
					const trip = tripObj.response
					const tripLegs = trip.tripLegs.map(getters.getTripLeg)

					for (const tripLeg of tripLegs) {
						if (!(tripLeg && tripLeg.tripLegStatusId !== 3 && tripLeg.scheduledStartTime && tripLeg.scheduledEndTime)) {
							continue
						}

						const { tripLegSchedulerStartTimestamp, tripLegSchedulerEndTimestamp, startTs, colSpan } = getTripLegMoments(tripLeg)
						if (!(tripLegSchedulerStartTimestamp && tripLegSchedulerEndTimestamp && startTs && colSpan)) {
							continue
						}

						// set all vehicles to show clickable button and highlight if selected
						for (const [contractId, contractTsObj] of Object.entries(vehicleTrips)) {
							if (!contractTsObj[startTs]) {
								continue
							}

							if (trip.activeContractId && trip.activeContractId.toString() === contractId) {
								contractTsObj[startTs].trips[tripLeg.tripId.toString()] = {
									tripId: tripLeg.tripId,
									tripLegIds: [tripLeg.tripLegId],
								}
							}

							contractTsObj[startTs].show = true
							contractTsObj[startTs].selectable = true
							contractTsObj[startTs].colSpan = colSpan

							// set other timestamps to not show
							for (let i = 1; i < colSpan; i++) {
								const colTs = tripLegSchedulerStartTimestamp
									.clone()
									.add(state.cellIntervalInMinutes * i, "minutes")
									.format("HH:mm")
								if (!contractTsObj[colTs]) {
									console.log("Could not find colTs to skip", colTs)
									continue
								}
								contractTsObj[colTs].show = false
							}
						}
					}
				}
			}
			return vehicleTrips
		},
	},
}
