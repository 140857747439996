export const messages = {
	en: {
		message: {
			Order: "Order | Orders",
			Project: "Project | Projects",
			Panel: "Panel | Panels",
			Material: "Material | Materials",
			Board: "Board | Boards",
			Box: "Box | Boxes",
		},
	},
}
