<template>
	<span class="skeleton" :style="skeletonStyle"></span>
</template>
<style>
.skeleton {
	animation: skeleton-pulse 1s ease-in-out infinite;
}

@keyframes skeleton-pulse {
	0% {
		opacity: 0.4;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0.4;
	}
}
</style>
<script>
export default {
	name: "skeletonLoader",
	props: ["h", "w", "br", "bg", "m", "b"],
	computed: {
		skeletonStyle() {
			// Generate the style object dynamically based on the provided props
			return {
				height: this.h ? this.h : "24px",
				width: this.w ? this.w : "100%",
				borderRadius: this.br ? this.br : "15px",
				backgroundColor: this.bg ? this.bg : "#eaeaea",
				margin: this.m ? this.m : "0",
				border: this.b ? this.b : "0",
			}
		},
	},
}
</script>
