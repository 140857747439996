<template src="./labelWithToolTip.html"></template>
<script>
export default {
	name: "labelWithToolTip",
	props: ["labelName", "labelSize", "labelColor", "labelClass", "ttContent", "contentSize", "iconName", "iconSize", "iconColor"],
	methods: {
		setTTAxis(event) {
			const e = event.target.getBoundingClientRect()
			const x = e.left,
				y = e.top
			this.$refs.ttcontainer.style.left = (x || 0) - 14 + "px"
			this.$refs.ttcontainer.style.top = (y || 0) + 20 + "px"
		},
		setTTContent() {
			setTimeout(() => {
				if (this.$refs.ttcontainer && this.ttContent) {
					this.$refs.ttcontainer.innerHTML = this.ttContent
				}
			}, 10)
		},
	},
	mounted() {
		this.setTTContent()
		document.addEventListener(
			"scroll",
			(e) => {
				// To hide tt content on page scrolls
				if (this.$refs.ttcontainer) {
					this.$refs.ttcontainer.style.left = -10000 + "px"
					this.$refs.ttcontainer.style.top = -10000 + "px"
				}
			},
			true
		)
	},
	watch: {
		ttContent() {
			this.setTTContent()
		},
	},
}
</script>
<style>
.label-with-tt {
	position: relative;
}

.tt-icon {
	opacity: 0.9;
}

.tt-icon:hover + .tt-content {
	display: block !important;
}

.tt-content {
	font-size: 13px;
	font-weight: 400;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	border: 1px solid #ececec;
	padding: 7px 15px 7px 15px;
	z-index: 10;
	--animate-duration: 250ms;
}
</style>
