import Vue from "vue"
import Multiselect from "vue-multiselect"
import Paginate from "vuejs-paginate"

import addVendor from "./addVendor/addVendor.vue"
import vehicleNumber from "./vehicleNumber/vehicleNumber"
import skeletonLoader from "./skeletonLoader/skeletonLoader"
import JsonEditorVue from 'json-editor-vue'

Vue.component("multiselect", Multiselect)
Vue.component("paginate", Paginate)
Vue.component(addVendor.name, addVendor)
Vue.component("vehicleNumber", vehicleNumber)
Vue.component("skeletonLoader", skeletonLoader)
Vue.component("JsonEditorVue", JsonEditorVue)