<template src="./vehicleNumber.html"></template>
<script>
import moment from "moment"
import labelWithToolTip from "./../../components/labelWithToolTip/labelWithToolTip.vue"
export default {
	name: "vehicleNumber",
	props: ["vehicleNumber", "vehicle", "labelSize", "labelColor", "contentSize", "iconSize"],
	computed: {
		tt() {
			let color,
				content = ""
			if (this.vehicle) {
				const today = moment(new Date().setHours(0, 0, 0, 0)).add(1, "days")
				const permitExp = moment(this.vehicle.permitExpiryDate || null).diff(today, "days")
				const fitnessExp = moment(this.vehicle.fitnessExpiryDate || null).diff(today, "days")
				const taxExp = moment(this.vehicle.taxExpiryDate || null).diff(today, "days")
				const emissionExp = moment(this.vehicle.emissionExpiryDate || null).diff(today, "days")
				const insuranceExp = moment(this.vehicle.insuranceExpiryDate || null).diff(today, "days")

				// Setting up tooltip props
				if (permitExp < 0 || fitnessExp < 0 || taxExp < 0 || emissionExp < 0 || insuranceExp < 0) {
					color = color || "rgb(226 0 0)"
					content +=
						"<ul class='vehicleTTPop expired mb-1 pl-3'><li class='m-0 p-0'>" +
						(
							(permitExp < 0 ? "Permit, " : "") +
							(fitnessExp < 0 ? "Fitness, " : "") +
							(taxExp < 0 ? "Tax, " : "") +
							(emissionExp < 0 ? "Emission, " : "") +
							(insuranceExp < 0 ? "Insurance, " : "") +
							"has expired!"
						)
							.replace(/,([^,]*)$/, "$1")
							.replace(/,([^,]*)$/, "\ and$1") +
						"</li></ul>"
				}
				if (
					(permitExp >= 0 && permitExp < 10) ||
					(fitnessExp >= 0 && fitnessExp < 10) ||
					(taxExp >= 0 && taxExp < 10) ||
					(emissionExp >= 0 && emissionExp < 10) ||
					(insuranceExp >= 0 && insuranceExp < 10)
				) {
					color = color || "rgb(242 161 0)"
					content +=
						"<ul class='vehicleTTPop expiring-soon mb-1 pl-3'><li class='m-0 p-0'>" +
						(
							(permitExp >= 0 && permitExp < 10 ? "Permit, " : "") +
							(fitnessExp >= 0 && fitnessExp < 10 ? "Fitness, " : "") +
							(taxExp >= 0 && taxExp < 10 ? "Tax, " : "") +
							(emissionExp >= 0 && emissionExp < 10 ? "Tax, " : "") +
							(insuranceExp >= 0 && insuranceExp < 10 ? "Insurance, " : "") +
							"will expire soon!"
						)
							.replace(/,([^,]*)$/, "$1")
							.replace(/,([^,]*)$/, "\ and$1") +
						"</li></ul>"
				}
			}
			return { color, content }
		},
	},
	components: {
		labelWithToolTip,
	},
}
</script>
<style>
ul.vehicleTTPop li {
	list-style: disc;
	color: rgb(14, 14, 14);
	font-size: 13px !important;
	font-weight: 400 !important;
}

ul.vehicleTTPop.expired li::marker {
	color: rgb(226 0 0);
}

ul.vehicleTTPop.expiring-soon li::marker {
	color: rgb(233, 171, 0);
}
</style>
