import Vue from "vue"

export default (serviceProviderName, coordinates, addReverseDirections) => {
	return new Promise((resolve, reject) => {
		const mToKM = (meters) => {
			return +(Math.round(meters / 1000 + "e+2") + "e-2")
		}
		const secToMin = (seconds) => {
			return Math.floor(seconds / 60)
		}

		const fetchAPI = () => {
			if (!(serviceProviderName && coordinates && coordinates.length >= 2 && coordinates.length <= 25)) {
				reject(`Error! Invalid Params!`)
				return
			}

			if (serviceProviderName === "googlemaps") {
				Vue.prototype.$googleMapsLoader.load().then(() => {
					const directionsService = new google.maps.DirectionsService()
					directionsService.route(
						{
							origin: new google.maps.LatLng(coordinates[0].lat, coordinates[0].lng),
							destination: new google.maps.LatLng(coordinates[coordinates.length - 1].lat, coordinates[coordinates.length - 1].lng),
							waypoints: coordinates
								.filter((e, index) => index !== 0 && index !== coordinates.length - 1)
								.map((e) => {
									return { location: new google.maps.LatLng(e.lat, e.lng) }
								}),
							travelMode: google.maps.TravelMode.DRIVING,
						},
						(results, status) => {
							if (status === "OK" || status === "ZERO_RESULTS") {
								// Mapping response to one standard format
								resolve(
									results.routes.map((item) => {
										return {
											polyline: item.overview_polyline,
											legs: item.legs.map((leg) => {
												return {
													distance: mToKM(leg.distance.value),
													duration: secToMin(leg.duration.value),
													startAddress: leg.start_address,
													endAddress: leg.end_address,
												}
											}),
											totalDistance: mToKM(item.legs.reduce((a, b) => a + (b.distance.value || 0), 0)),
											totalDuration: secToMin(item.legs.reduce((a, b) => a + (b.duration.value || 0), 0)),
											waypointsOrder: Array.from(coordinates, (_, index) => index),
										}
									})
								)
							} else {
								reject(status)
							}
						}
					)
				})
			} else if (serviceProviderName === "mapbox") {
				const URL = `${process.env.VUE_APP_MAPBOX_API_URL}directions/v5/mapbox/driving/${coordinates.map((e) => `${e.lng},${e.lat}`).join(";")}.json?&overview=full&access_token=${
					process.env.VUE_APP_MAPBOXTOKEN
				}`

				fetch(encodeURI(URL))
					.then((response) => {
						if (response.status === 200) {
							response
								.json()
								.then((response) => {
									if (response.code === "Ok" || response.code === "NoRoute" || response.code === "NoSegment") {
										// Mapping response to one standard format
										resolve(
											response.routes.map((item) => {
												return {
													polyline: item.geometry,
													legs: item.legs.map((leg, index) => {
														return {
															distance: mToKM(leg.distance),
															duration: secToMin(leg.duration),
															startAddress: leg.summary,
															endAddress: item.legs[index + 1] ? item.legs[index + 1].summary : addReverseDirections ? item.legs[0].summary : null,
														}
													}),
													totalDistance: mToKM(item.distance),
													totalDuration: secToMin(item.duration),
													waypointsOrder: Array.from(coordinates, (_, index) => index),
												}
											})
										)
									} else {
										reject(response.code)
									}
								})
								.catch((error) => {
									reject(error)
								})
						} else {
							reject(response.status)
						}
					})
					.catch((error) => {
						reject(error)
					})
			} else {
				reject(`Invalid Service Provider!`)
			}
		}

		// main function call
		if (addReverseDirections) {
			coordinates = coordinates.concat(coordinates.slice(0, coordinates.length - 1).reverse())
			fetchAPI()
		} else {
			fetchAPI()
		}
	})
}
