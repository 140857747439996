import fetchApiRequest from "@/app/store/fetchApiRequest"

export function formatMaterialCode(value) {
	if (!value) return ""
	value = value.toString()
	return value.slice(value.indexOf("_") + 1)
}
export function titleCaseFromat(str) {
	if (!str) return ""
	else return str.split(/(?=[A-Z][a-z\d])/).join(" ")
}
export function ssccNOFormat(str) {
	if (!str) return ""
	else return str.toString().replaceAll("`", "")
}
export function debounceSearch(callbackFn, delay) {
	let searchTimeOut
	return (...args) => {
		if (searchTimeOut) {
			clearTimeout(searchTimeOut)
		}
		searchTimeOut = setTimeout(() => {
			callbackFn(...args)
		}, delay)
	}
}

export function printWithPageSize(pageSize) {
	const css = `
                @media print {
                    @page {
                        size: ${pageSize};
                        margin: 0;
                    }
                    @page :first {
                        margin-top: -16px !important;
                        margin-bottom: -16px !important;
                    }
                }
                `
	const panelPrintStyles = document.createElement("style")
	panelPrintStyles.setAttribute("type", "text/css")
	panelPrintStyles.appendChild(document.createTextNode(css))
	document.head.appendChild(panelPrintStyles)
	window.print()
	panelPrintStyles.remove()
}

export async function fetchSignedUrlsForItems(items) {
	const itemsUrlArrStr = items
		.filter((item) => item.orderItemImageKey)
		.map((item) => item.orderItemImageKey)
		.join(",")
	const response = await fetchApiRequest("displayImage", {
		itemsUrlArrStr,
	})
	return items.map((item) => ({
		...item,
		signedImageUrl: response.signedUrls.find((url) => item.orderItemImageKey?.includes(url.key))?.orderItemImageDispUrl,
	}))
}

export function validateImportExcelFile(files, acceptedFileTypes, maxFileSizeAllowed, maxFilesAllowed = 1) {
	files = maxFilesAllowed > 1  ? files : files[0]
	const checkFileExtentions = (name) => {
		const regex = new RegExp("(.*?).(" + acceptedFileTypes.join("|") + ")$")
		return regex.test(name.toLowerCase())
	}
	const checkFileSizeValid = (file) => {
		const fileSize = file.size / 1024 / 1024
		return fileSize <= maxFileSizeAllowed
	}
	if (!checkFileExtentions(files.name)) {
		return {
			errMsg: "Sorry, this file type is not permitted for security reasons!",
			isValid: false,
		}
	}
	if (!(files.name && files.name.length <= 100)) {
		return { errMsg: `File name cannot exceed 100 characters`, isValid: false }
	}
	if (!checkFileSizeValid(files)) {
		return {
			errMsg: `Files larger than ${maxFileSizeAllowed}MB are not currently supported`,
			isValid: false,
		}
	}
	return { errMsg: null, isValid: true }
}

function formatTime(t) {
	if (!t) return "00"
	return t > 9 ? t : `0${t}`
}
export function getDuration(hr, min, seconds) {
	return `${hr > 0 ? `${formatTime(hr)} hrs:` : ''}${formatTime(min)} min${hr > 0 ? '': `:${formatTime(seconds)} sec`}`
	// return `${formatTime(hr)}:${formatTime(min)}:${formatTime(seconds)}`
}

export function capitalizeEachWord(str, caseType) {
	let words
	switch (caseType) {
		case "kebab-case":
			words = str.split('-')
			break;
		case "snake_case":
			words = str.split("_")
			break;
		case "camleCase":
			words = str.split(/(?=[A-Z])/g)
			break;
		default:
			words = str.split(/[-_]|\B(?=[A-Z])/)	// All cases
	}
	return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
}